import { PanelBar, PanelBarItem, StackLayout } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import { FSTFLicensePack } from "../organisms/FSTF.LicensePack";
import { FSTFDefaultTemplate } from "../templates/FSTF.DefaultTemplate";
import { useEffect, useState, useContext, MouseEvent } from "react";
import { IFSLicensePack } from "../../types/IFSLicensePack";
import { FSAppContext } from "../../providers/FSTF.Context";
import { useNavigate, useParams } from "react-router-dom";
import { ConfirmDialog } from "../atoms/FSTF.ConfirmDialogs";

export const FSTFLicenses = () => {
  const { customerId } = useParams();
  
  const { isSiteAdmin, user, dataProvider, isDev, setPageTitle } = useContext(FSAppContext);
  const [licensePacks, setLicensePacks] = useState<IFSLicensePack[] | null>();
  const [customerName, setCustomerName] = useState<string|null>();

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [deleteLicense, setDeleteLicense] = useState<string | null>(null);


  useEffect(() => {
    if (customerId === undefined || customerId === null) {
      //if an id was not passed, then use the user's id to get their licenses
      dataProvider?.getUserCustomers(user.id!).then((data) => {
      if (data.length > 0) {
          dataProvider?.getLicensePacksForCompany(data[0].customerId!).then((data) => {
            setLicensePacks(data);
          });
        }
      })
    } else {
      dataProvider?.getLicensePacksForCompany(customerId).then((data) => {
        setLicensePacks(data);
      });
    }

    if (customerId !=null) {
      dataProvider?.getCustomer(customerId).then((cdata) => {
        if (cdata!=null && cdata !=undefined){
          setCustomerName(cdata.name);
        } else {
          navigate('/');
        }
      });
    }

    else {
      setCustomerName(null);
    }
  }, [customerId,setLicensePacks,setCustomerName]);

  useEffect(() => {
    if (customerName !== null && customerName !== undefined) {
      setPageTitle(`Licenses - ${customerName}` );
    } 
  
    else {
      setPageTitle("Your Licenses");
    }
  }, [customerName]);

  const addLicensePackOnClick = (): void => {
    navigate(`/licenses/add/${customerId}`);
  };
  
  const deleteLicensePackOnClick = (e: MouseEvent<HTMLButtonElement>): void => {
    const deleteTarget = e.currentTarget.id;
    setDeleteLicense(deleteTarget); 
    setShowConfirmDialog(true);
  };

  const handleConfirmDelete = () => {
    if (deleteLicense) {
      dataProvider
        ?.deleteLicensePack(deleteLicense)
        .then(() => {
          setLicensePacks(licensePacks?.filter((lp) => lp.id !== deleteLicense));
        })
        .finally(() => {
          setDeleteLicense(null);
          setShowConfirmDialog(false);
        });
    }
  };

  const handleCancelDelete = () => {
    setDeleteLicense(null);
    setShowConfirmDialog(false);
  };

  const editLicensePackOnClick = (e: MouseEvent<HTMLButtonElement>): void => {
    navigate(`/licenses/edit/${e.currentTarget.id}`);
  };

  const downloadLicensePackOnClick = (e: MouseEvent<HTMLButtonElement>): void => {
    navigate(`/licenses/eula/${e.currentTarget.id}`);
  };

  let navigate = useNavigate();

  return (
    <FSTFDefaultTemplate>
      <StackLayout className="layout-container k-m-4" orientation='vertical' gap={10}>
      {licensePacks === undefined || licensePacks == null ? (
        <div>No Licenses/loading</div>
      ) : (
        <>              
          <h2>
            {customerName === undefined || customerName === null
              ? "Your Licenses"
              : `Licenses for ${customerName}`}
          </h2>   
          <div className ="k-license-panelbar-custom">       
          <PanelBar expandMode={"single"}>
            {licensePacks.map((licensePack) => {
              return (
                <PanelBarItem
                  key={licensePack.id}
                  title={`${licensePack.name}`}
                  expanded={false}>
                  <FSTFLicensePack {...licensePack} />
                  <div className="k-card-divider"></div>
                  <div className="fs-license-btngroup"> 
                  {isSiteAdmin ? (
                    <> 
                      <Button themeColor={"primary"} 
                        className="k-button k-primary-solid-primary"
                        id={licensePack.id}
                        onClick={(e) => downloadLicensePackOnClick(e)}
                      >Download</Button> &nbsp;                  
                      <Button themeColor={"info"} 
                        className="k-button k-button-solid-info"
                        id={licensePack.id}
                        onClick={(e) => editLicensePackOnClick(e)}
                      >Edit</Button>&nbsp;
                      <Button themeColor={"secondary"} 
                        className="k-button k-primary-solid-secondary"
                        id={licensePack.id}
                        onClick={(e) => deleteLicensePackOnClick(e)}
                        >Delete</Button>
                    </>
                  ) : (
                    <><Button themeColor={"primary"} 
                    className="k-button k-primary-solid-primary"
                    id={licensePack.id}
                    onClick={(e) => downloadLicensePackOnClick(e)}
                  >Download</Button></>
                  )}
                  </div>
                </PanelBarItem>
              );
            })}
          </PanelBar></div>
          {isSiteAdmin ? (
            <div>
            <Button size={"medium"} 
              fillMode="outline"           
              title="Add License Pack"
              className="k-button k-primary"
              onClick={addLicensePackOnClick}
            >Add License Pack</Button></div>
          ) : (
            <></>
          )}
        </>
      )}
      {showConfirmDialog && (
          <ConfirmDialog
            title="Delete License"
            message="Are you sure you want to delete this license?"
            onConfirm={handleConfirmDelete}
            onCancel={handleCancelDelete}
          />
        )}
      </StackLayout>
    </FSTFDefaultTemplate>
  );
};
