import { Grid } from "@progress/kendo-react-grid";
import { Card, CardBody } from "@progress/kendo-react-layout";
import { Loader } from "@progress/kendo-react-indicators";
import { FC } from "react";

export interface CardGridProps {
  title: string;
  error?: string;
  loading?: boolean;
  data: any[];
  children?: React.ReactNode;
}

export const CardGrid: FC<CardGridProps> = ({
  title,
  loading = false,
  error,
  data,
  children,
}) => {
  let content;

  if (loading) {
    content = <Loader type="infinite-spinner" size="medium" />;
  } else if (error) {
    content = <p>{error}</p>;
  } else {
    content = (
      <Grid
        data={data}
        resizable={true}
        scrollable={"scrollable"}
        sortable={true}
        size={"small"}
      >
        {children}
      </Grid>
    );
  }
  return (
    <Card>
      <CardBody>
        <h2>{title}</h2>
        {content}
      </CardBody>
    </Card>
  );
};
