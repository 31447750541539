
import { DependencyList, useEffect, useState } from "react";

type AsyncCallable<T> = () => Promise<T>;

export function useAsyncResult<T>(
  fn: AsyncCallable<T>,
  deps: DependencyList
): [T | undefined, boolean, any];

export function useAsyncResult<T>(
  fn: AsyncCallable<T>,
  deps: DependencyList,
  initialState: T
): [T, boolean, any];

/**
 * A custom hook that handles asynchronous operations and manages their state.
 *
 * @template T - The type of the result returned by the asynchronous function.
 * 
 * @param {AsyncCallable<T>} fn - The asynchronous function to be executed.
 * @param {DependencyList} deps - The list of dependencies that will trigger the effect when changed.
 * @param {T} [initialState] - The initial state of the result.
 * 
 * @returns {[T | undefined, boolean, any]} - An array containing the result of the asynchronous function,
 * a boolean indicating the loading state, and any error that occurred during the execution.
 * 
 * @example
 * ```typescript
 * const [data, loading, error] = useAsyncResult(async () => {
 *   const response = await fetch('/api/data');
 *   return response.json();
 * }, []);
 * ```
 */
export function useAsyncResult<T>(
  fn: AsyncCallable<T>,
  deps: DependencyList,
  initialState?: T
) {
  const [result, setResult] = useState(initialState);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<any>(null);
  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        setError(null);
        const r = await fn();
        setResult(r);
      } catch (e) {
        setError(e);
      } finally {
        setLoading(false);
      }
    })();
  }, deps);

  return [result, loading, error];
}
