import {
  GridLayoutItem,
  GridLayoutItemProps,
} from "@progress/kendo-react-layout";

export const FSTFLogo = (props: GridLayoutItemProps) => {
  return (
    <GridLayoutItem id="logo" row={1} col={1}>
      <img
        src="trusted-filter-logo-pink-white.png"
        alt="TrustedFilter™ SECUREpane"
        height={"40px"}
      />
    </GridLayoutItem>
  );
};
