import "reflect-metadata";

interface FSTFDefaultTemplateProps {
  children: React.ReactNode;
}

export const FSTFDefaultTemplate: React.FC<FSTFDefaultTemplateProps> = ({
  children,
}) => {
  return <div className="app-main">{children}</div>;
};
