import { useContext, useEffect } from "react";
import { FSAppContext } from "../../providers/FSTF.Context";
import { Card, CardBody } from "@progress/kendo-react-layout";

export const FSTFAbout = (props: any) => {
  const { setPageTitle } = useContext(FSAppContext);

  useEffect(() => {
    setPageTitle("About");
  }, []);

  return (
    <div className="k-m-5">
      <Card>
        <CardBody>About page under development</CardBody>
      </Card>
    </div>
  );
};
