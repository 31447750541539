import React, { useContext } from "react";
import {
  GridLayoutItem,
  GridLayoutItemProps,
  Menu,
  MenuItem,
  MenuSelectEvent,
  StackLayout,
} from "@progress/kendo-react-layout";
import { withRouter } from "../../providers/FSTF.WithRouter";
import { useNavigate } from "react-router-dom";
import { FSAppContext } from "../../providers/FSTF.Context";
import {
  homeIcon,
  paperPlaneIcon,
  gearsIcon,
  accessibilityIcon,
  userIcon,
  questionCircleIcon,
  fileTxtIcon,
  gridLayoutIcon,
  arrowRightIcon,
  logoutIcon,
  gearIcon,
  infoCircleIcon,
  layout2By2Icon,
} from "@progress/kendo-svg-icons";

const FSTFNavigation = (props: GridLayoutItemProps) => {
  let navigate = useNavigate();
  const { isDev, isSiteAdmin, isValidCustomerAccess, isLoggedIn, user } =
    useContext(FSAppContext);
  const { currentPage, setCurrentPageValue } = useContext(FSAppContext);
  const { sitePermissionsService } = useContext(FSAppContext);
  const onSelect = (event: MenuSelectEvent) => {
    if (setCurrentPageValue) {
      setCurrentPageValue(event.item.data.page);
    }
    navigate(event.item.data.route);
  };

  const GetPermission = (component: string) => {
    let rs = sitePermissionsService?.getRolesPermission({
      roles: user.fullSiteRoles,
      component: component,
      context: null,
    });
    return rs;
  };

  // https://www.telerik.com/kendo-react-ui/components/layout/menu/
  // https://www.telerik.com/kendo-react-ui/components/styling/icons/#toc-list-of-font-icons/
  // https://www.telerik.com/kendo-react-ui/components/utils/svgicon/#toc-svg-icons-list/
  // where icons are camelcased, use hyphenated in the cssClass, e.g. paperPlane -> paper-plane

  const { children, ...gridProps } = props;

  return (
    <GridLayoutItem {...gridProps}>
      <StackLayout orientation="vertical" className="app-nav" gap={3}>
        <Menu onSelect={onSelect} vertical>
          <MenuItem
            text={isLoggedIn ? "Dashboard" : "Home"}
            cssClass={
              currentPage === "" ? "active menu-dashboard" : "menu-dashboard"
            }
            data={{ route: "/", page: "" }}
            svgIcon={isLoggedIn ? layout2By2Icon : homeIcon}
          ></MenuItem>
          (
          <MenuItem
            text="Products"
            cssClass={
              currentPage === "products"
                ? "active menu-products"
                : "menu-products"
            }
            data={{ page: "products", route: "/products" }}
            svgIcon={gridLayoutIcon}
          />
          )
          {(GetPermission("Navigation.Licenses")?.canView ||
            isValidCustomerAccess) && (
            <MenuItem
              text="Licenses"
              cssClass={
                currentPage === "licenses"
                  ? "active menu-licenses"
                  : "menu-licenses"
              }
              data={{ page: "licenses", route: "/licenses" }}
              svgIcon={fileTxtIcon}
            />
          )}
          {(isSiteAdmin || isValidCustomerAccess) && (
            <MenuItem
              text="Customers"
              cssClass={
                currentPage === "customers"
                  ? "active menu-customers"
                  : "menu-customers"
              }
              data={{ page: "customers", route: "/customers" }}
              svgIcon={accessibilityIcon}
            />
          )}
          {isSiteAdmin && (
            <MenuItem
              text="Pane Login Accounts"
              cssClass={
                currentPage === "users" ? "active menu-users" : "menu-users"
              }
              data={{ page: "users", route: "/users" }}
              svgIcon={userIcon}
            />
          )}
          <MenuItem
            text="Packages"
            cssClass={
              currentPage === "packages"
                ? "active menu-packages"
                : "menu-packages"
            }
            data={{ page: "packages", route: "/packages" }}
            svgIcon={paperPlaneIcon}
          />
          {isDev && isSiteAdmin && (
            <MenuItem
              text="Debugging"
              cssClass={
                currentPage === "debug" ? "active menu-debug" : "menu-debug"
              }
              data={{ page: "debugging", route: "/debugging" }}
              svgIcon={questionCircleIcon}
            />
          )}
          {!isLoggedIn ? (
            <MenuItem
              text="Login"
              cssClass={
                currentPage === "login" ? "active menu-login" : "menu-login"
              }
              data={{ page: "login", route: "/login" }}
              svgIcon={arrowRightIcon}
            />
          ) : (
            <MenuItem
              text="Logout"
              cssClass="menu-logout"
              data={{ page: "logout", route: "/logout" }}
              svgIcon={logoutIcon}
            />
          )}
          {isLoggedIn && (
            <MenuItem
              text="Configuration"
              cssClass={
                currentPage === "configuration"
                  ? "active menu-configuration"
                  : "menu-configuration"
              }
              data={{ page: "configuration", route: "/configuration" }}
              svgIcon={gearIcon}
            />
          )}
          <MenuItem
            text="About"
            cssClass={
              currentPage === "about" ? "active menu-about" : "menu-about"
            }
            data={{ page: "about", route: "/about" }}
            svgIcon={infoCircleIcon}
          />
          {isSiteAdmin && (
            <MenuItem
              text="Administration"
              cssClass={
                currentPage === "administration"
                  ? "active menu-admin"
                  : "menu-admin"
              }
              data={{ page: "administration", route: "/administration" }}
              svgIcon={gearsIcon}
            />
          )}
        </Menu>
        <div className="navigation-child">{props.children}</div>
      </StackLayout>
    </GridLayoutItem>
  );
};
export default withRouter(FSTFNavigation);
