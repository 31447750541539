import { Button } from "@progress/kendo-react-buttons";
import { useState } from "react";
import { Label } from "@progress/kendo-react-labels";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import IFSCustomerUsers from "../../types/IFSCustomerUsers";
import { DropDownList, MultiSelect } from "@progress/kendo-react-dropdowns";
import IFSUsers from "../../types/IFSUsers";
import { StackLayout } from "@progress/kendo-react-layout";
import IFSCustomer from "../../types/IFSCustomer";
import { IFSCustomerRoles, IFSRoles } from "../../types/IFSRoles";


interface FSTFCustomerUserRoleFormProps {
  onSubmit: (finalUserDetail: IFSCustomerUsers,rolesData:string[]) => void;
  onCancel: () => void;
  allActiveCustomers: IFSCustomer[];
  customerRolesAvailable: IFSRoles[];
  selectedUserId: string;
  assignedCustomerRoles: IFSCustomerUsers[];
  selectedUser: IFSUsers | null;
  type: string;
}

export const FSTFCustomerUserRoleForm = (
  { 
    onSubmit, 
    onCancel,
    allActiveCustomers,
    customerRolesAvailable,
    selectedUserId,
    assignedCustomerRoles,
    selectedUser,
    type
  } : FSTFCustomerUserRoleFormProps) => {     

  const [selectedCustomerUser, setSelectedCustomerUser] = useState<IFSCustomerUsers>();
  const [selectedRoles, setSelectedRoles] = useState<IFSRoles[]>();
  const [showRolesOption, setShowRolesOption] = useState(false);
  
  const onRoleSelectionChange = (event:any) => {
    setSelectedRoles(event.value);
  }
  
  const handleCustomerChange = (e:any) => {
    const customerId = e.target.value.id;
    setSelectedCustomerUser({ ...selectedCustomerUser,"userId":selectedUserId, "customerId": customerId });  
    setShowRolesOption(true);
    setSelectedRoles([]);
    if(assignedCustomerRoles?.length>0){      
      let selected:IFSRoles[]=[];
      let selectedRoles=selectedUser!.companyRoles
      customerRolesAvailable?.forEach((role: IFSRoles) => {
        selectedRoles?.forEach((r: IFSCustomerRoles )=>{
            if(r.roleName == role.roleName && r.companyId == customerId){
              selected.push(role)
            }
        })
      });
      setSelectedRoles(selected);
    }
  };

  const handleSubmit = (event:any) => { 
    event.preventDefault(); 
    const newRoles = selectedRoles?.map(sr => sr.roleName);
    onSubmit(selectedCustomerUser!,newRoles!);      
  };

  return (
    <Dialog title={type} onClose={onCancel} className="fs-add-dialogbox">
        <div className="fs-role-selection">User :{selectedUser!.firstName} {selectedUser!.lastName}</div>
        <div className="fs-role-selection">Email :{selectedUser!.email}</div>
        <hr/>
        <form onSubmit={handleSubmit}>
          <div className="fs-role-selection">
            <Label editorId="customerId">Select Customer:</Label> 
            <DropDownList   textField="name" name="customerId"
              dataItemKey="id"
              data={allActiveCustomers}
              onChange={handleCustomerChange}/>
          </div>
          <div className="fs-role-selection">{
            showRolesOption && (
              <div className="fs-role-selection"> 
                <Label editorId="companyRole">Company Roles</Label>  
                  <MultiSelect
                  key={selectedCustomerUser?.customerId || 'default-key'}
                  name="companyRole"
                  data={customerRolesAvailable}
                  onChange={onRoleSelectionChange}
                  value={selectedRoles}
                  placeholder="Please select ..."
                  textField="description"
                  dataItemKey="roleId"
                /> 
              </div>  
            )
          }
          </div>
          <div className="k-d-flex k-gap-2 k-mt-5 k-mb-5 k-justify-content-end">
            <Button themeColor={"light"} onClick={onCancel}>Cancel</Button>
            <Button themeColor={"primary"} className="k-button k-primary-solid-primary" type="submit">
              {type}
            </Button>
          </div>      
      </form>
    </Dialog>
  );
};