import React, { useContext } from "react";

import {
  AppBar,
  AppBarSection,
  AppBarSpacer,
  GridLayoutItem,
  GridLayoutItemProps,
} from "@progress/kendo-react-layout";
import {
  FSEnvironmentContext,
  IFSEnvironmentProps,
} from "../../providers/FSTF.EnvironmentProvider";

export const FSTFFooter = (props: GridLayoutItemProps) => {
  let thisYear = new Date().getFullYear();
  const { environmentService } =
    useContext<IFSEnvironmentProps>(FSEnvironmentContext);
  const APP_VERSION = environmentService?.getEnvironment().AppVersion;
  const APP_BUILD = environmentService?.getEnvironment().AppBuildId;

  const { className, ...gridProps } = props;
  return (
    <GridLayoutItem {...gridProps} className={className || "footer"}>
      <AppBar position="bottom">
        <AppBarSection>
          <img
            src="4-secure.webp"
            alt="4-Secure TrustedFilter™ SECUREpane"
            height={"50px"}
          />
        </AppBarSection>
        <AppBarSpacer style={{ width: 1 }} />
        <AppBarSection>Connecting The Disconnected</AppBarSection>
        <AppBarSpacer style={{ width: 1 }}>|</AppBarSpacer>
        <AppBarSection>Copyright &copy; {thisYear} 4Secure Ltd. </AppBarSection>
        <AppBarSpacer style={{ width: 1 }}>|</AppBarSpacer>
        <AppBarSection>All rights reserved</AppBarSection>
        <AppBarSpacer style={{ width: 4 }}>|</AppBarSpacer>
        <AppBarSection className="fs-app-version">
          {APP_VERSION}-{APP_BUILD}
        </AppBarSection>
      </AppBar>
    </GridLayoutItem>
  );
};
