import {
  Button,
} from "@progress/kendo-react-buttons";
import { Loader } from "@progress/kendo-react-indicators";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import IFSAppDownload from "../../types/IFSAppDownload";
import IFSDocumentation from "../../types/IFSDocumentation";

export type Downloadable = {
  download: IFSAppDownload | IFSDocumentation;
  isDownloading: boolean;
}

export interface DownloadPanelBarProps {
  downloadables: Downloadable[];
  handleDownload: (item: Downloadable) => void;
  handleToggleActive: (item: Downloadable) => void;
  isSiteAdmin: boolean;
  activeClassName: string;
  archivedClassName: string; 
}

const DownloadPanelBar = (props: DownloadPanelBarProps) => {

  const { 
    downloadables, activeClassName, archivedClassName, 
    handleToggleActive, handleDownload, isSiteAdmin 
  } = props;
  
  return (
    <PanelBar className="fs-download-panel">
      {downloadables.map((item: Downloadable, index: number) => {
        const title = `Version ${item.download.version} 
        (${new Date(item.download.createdDate).toLocaleDateString()}) 
        ${isSiteAdmin ? item.download.active ? " - (LIVE)" : " - (ARCHIVED)" : ""}`;
        
        return <PanelBarItem
                  expanded={index == 0 && item.download.active}
                  key={item.download.id}
                  className={item.download.active ? activeClassName : archivedClassName}
                  title={title}>
                  <p>
                    {item.download.description} </p>
                    <p>
                      <Button 
                        disabled={item.isDownloading} 
                        onClick={() => handleDownload(item)}
                        themeColor={"primary"} className="k-button k-primary-solid-primary">
                          {item.isDownloading ? "Downloading..." : "Download"}
                          {item.isDownloading && (
                            <Loader type="infinite-spinner" size="small" />
                          )}
                      </Button>
                      
                      {isSiteAdmin && (
                        <>&nbsp;<Button onClick={() => handleToggleActive(item)} 
                        themeColor={"primary"} className="k-button k-primary-solid-primary">{item.download.active ? "Archive" : "Unarchive"}</Button></>
                      )}
                      
                      </p>

                  </PanelBarItem>
                  
                })}
              </PanelBar>

  );
};

export default DownloadPanelBar;
