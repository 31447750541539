import { FSTFDefaultTemplate } from "../templates/FSTF.DefaultTemplate";
import { Button } from "@progress/kendo-react-buttons";
import { Card, CardBody, CardFooter, CardTitle,CardSubtitle,CardImage, CardHeader,StackLayout } from "@progress/kendo-react-layout";
import { useContext, useEffect } from "react";
import { FSAppContext } from "../../providers/FSTF.Context";
export const FSTFPackages = (props: any) => {

  const {setPageTitle} = useContext(FSAppContext);
  
  const secureImpex_description="SECUREimpex provides an extensive set of components for performing granular content inspection and validation with data transformation across a range of application layer data protocols. Includes the capability to decrypt, verify and re-encrypt a data stream. Encompasses a full-featured performant file transfer application optimised for cross-domain environments. Components are platform agnostic can be installed on any Unix-based system, including a unidirectional appliance. ";
  const secureCommand_description="Enables inherently two-way, bi-directional TCP applications which rely on request-response architecture to operate via two physically separate unidirectional flow control appliances. Can be installed directly onto a unidirectional appliance or flanking systems.";
  const secureStream_description="Screen casting application for securely monitoring and sharing a device screen from a low-security domain through a unidirectional appliance to a high-security domain in real-time."
  const secureConnect_description="Facilitates transfer of AMQP and MQTT messages across a segregated one-way link. Can be installed onto a unidirectional appliance or a flanking system."
  
  const trustedfilter_logo_image="trusted-filter-logo1.png";
  const packages=[
    {identity:"SECUREimpex.1.0.0",reference:"1.0.0",name:"SECUREimpex",description:secureImpex_description, image:trustedfilter_logo_image},
    {identity:"SECUREcommand.1.0.0",reference:"1.0.0",name:"SECUREcommand",description: secureCommand_description, image:trustedfilter_logo_image},
    {identity:"SECUREstream.1.0.0",reference:"1.0.0",name:"SECUREstream",description:secureStream_description, image:trustedfilter_logo_image},    
    {identity:"SECUREConnect.1.0.0",reference:"1.0.0",name:"SECUREConnect",description:secureConnect_description, image:trustedfilter_logo_image}    
  ]

  useEffect(()=>{ 
    setPageTitle("Packages");
  }, []);
  
  return (
    <FSTFDefaultTemplate>
        <StackLayout orientation="horizontal" className="fs-package-cards k-m-5 k-d-flex k-gap-5">
        
          {packages.map((packageItem) => {
            return (
              <Card key={packageItem.reference+packageItem.name} id={packageItem.reference} className="fs-package-card">
                <CardHeader>
                  <CardTitle>{packageItem.name}</CardTitle>
                  <CardSubtitle>{packageItem.reference}</CardSubtitle>
                </CardHeader>
                <CardBody className="fs-package-card-body">
                  <StackLayout orientation="vertical" >
                  <CardImage className="fs-package-card-image" src={packageItem.image}/>
                    {packageItem.description}                      
                  </StackLayout>
                </CardBody>
                <CardFooter>
                  <Button themeColor={"primary"} className="k-button k-primary-solid-primary" >{packageItem.name}-{packageItem.reference}</Button>
                </CardFooter>
              </Card>
            );
          })}
        </StackLayout>
    </FSTFDefaultTemplate>
  );
};
