import { FieldRenderProps, FieldWrapper } from "@progress/kendo-react-form";
import { Input, TextArea } from "@progress/kendo-react-inputs";
import { Hint, Label, Error } from "@progress/kendo-react-labels";
import { useRef } from "react";

export const LicenseTextAreaInputField = (
  fieldRenderProps: FieldRenderProps
) => {
  const {
    id,
    valid,
    label,
    required,
    validationMessage,
    hint,
    touched,
    ...others
  } = fieldRenderProps;

  const showValidationMessage: string | false | null =
    touched && validationMessage;
  const showHint: boolean = !showValidationMessage && hint;
  const hintId: string = showHint ? `${id}_hint` : "";
  const errorId: string = showValidationMessage ? `${id}_error` : "";

  const editorRef = useRef<any>(null);

  return (
    <FieldWrapper>
      <Label editorId={id} editorRef={editorRef} editorValid={valid}>
        {label}
      </Label>
      <div>
        <TextArea ref={editorRef} id={id} {...others} />
        {showHint && <Hint id={hintId}>{hint}</Hint>}
        {showValidationMessage && (
          <Error id={errorId}>{validationMessage}</Error>
        )}
      </div>
    </FieldWrapper>
  );
};
