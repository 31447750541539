import React, { useContext, useEffect, useState } from "react";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Input } from "@progress/kendo-react-inputs";
import { Card, CardBody, StackLayout } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { FSAppContext } from "../../providers/FSTF.Context";
import { useNavigate, useParams } from "react-router-dom";
import { FSTFDefaultTemplate } from "../templates/FSTF.DefaultTemplate";
import { Label } from "@progress/kendo-react-labels";

export const FSTFResetPassword = () => {
  const { dataProvider, setPageTitle } = useContext(FSAppContext);
  const navigate = useNavigate();
  const {resetcode} = useParams()
  const [message, setMessage] = useState('');
  const [showDialog, setShowDialog] = useState(false);
  const [resetSuccessful, setResetSuccessful] = useState(false); // New state to track reset success  
  const [passwordValidationMessage, setPasswordValidationMessage] = useState('');
  useEffect(() => {
    setPageTitle("Reset Password");
  }, []);
   const handleSubmit = async (e: any) => {   
    setPasswordValidationMessage("");
    if (e.password.length < 6 || !/\W/.test(e.password) || !/\d/.test(e.password) || !/[A-Z]/.test(e.password)){
      setPasswordValidationMessage("*Password must have least 6 chars,symbol,digit,upper-lowercase combination");
      return;
    } 
    if (e.password !== e.confirmPassword) {
      setMessage("Passwords do not match!");
      setShowDialog(true);
    } else {
      try {
        if (dataProvider) {
          const data = await dataProvider.resetPassword(e.email, e.password, resetcode || '');
          if (data === true) {
            setMessage('Your password has been reset successfully. Please Login.');
            setResetSuccessful(true); 
          } else {
            setMessage('Failed to reset password. Please ensure your reset link is correct and try again.');
            setResetSuccessful(false); 
          }
          setShowDialog(true);
        } else {
          throw new Error('DataProvider is undefined.');
        }
      } catch (error:any) {
        setMessage(`Unable to reset password: ${error.message}`);
        setShowDialog(true);
      }
    }
  };

  const handleCloseDialog = () => {
    setShowDialog(false);
    if (resetSuccessful) {
      navigate('/login'); // Navigate to login page if reset was successful
    }
  };

  return (
    <FSTFDefaultTemplate>
      {showDialog && (
        <Dialog title={"Reset Password"} onClose={handleCloseDialog} className="fs-dialogbox">
          <p>{message}</p>
          <DialogActionsBar>
            <Button themeColor={"primary"} className="k-button k-primary-solid-primary" onClick={handleCloseDialog}>OK</Button>
          </DialogActionsBar>
        </Dialog>
      )}
      <StackLayout orientation="horizontal" className="app-login">
        <Card>
          <CardBody>
            <Form
              onSubmit={handleSubmit}
              render={(formRenderProps) => (
                <FormElement>
                  <fieldset className={"k-form-fieldset"}>
                    <div className="k-mb-4">
                      <Label>Email</Label>
                      <Field name="email" component={Input} type="email" required={true} />
                    </div>
                    <div className="k-mb-4">
                      <Label>Password</Label>
                      <Field name={"password"} component={Input} type={"password"} />
                      {passwordValidationMessage && (
                        <div className="fs-resetpassword-passwordmatch">{passwordValidationMessage}</div>
                      )}
                    </div><div className="k-mb-4">
                      <Label>Confirm Password</Label>
                      <Field name="confirmPassword" component={Input}  type="password" required={true} />
                    </div>
                  </fieldset>
                  <div className="fs-login-btngroup">
                      <Button themeColor={"primary"} className="k-button k-primary-solid-primary" type="submit" >Reset</Button>
                  </div>
                  <br/>    
                </FormElement>
              )}
            />
          </CardBody>
        </Card>
      </StackLayout>
    </FSTFDefaultTemplate>
  );
};