import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  StackLayout,
} from "@progress/kendo-react-layout";
import { withRouter } from "../../providers/FSTF.WithRouter";
import { useContext, useMemo, useEffect, useState } from "react";
import { FSAppContext } from "../../providers/FSTF.Context";
import { useNavigate } from "react-router-dom";
import IFSProduct from "../../types/IFSProduct";
import IFSAppDownload from "../../types/IFSAppDownload";
import IFSDocumentation from "../../types/IFSDocumentation";
import { Button } from "@progress/kendo-react-buttons";
import {
  fileConfigIcon,
  filePdfIcon,
  fileZipIcon,
} from "@progress/kendo-svg-icons";
import { IFSLicensePack } from "../../types/IFSLicensePack";
import { Loader } from "@progress/kendo-react-indicators";
import { differenceInDays } from "date-fns";
import { useAsyncResult } from "../../Utilities/hooks";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { CardGrid } from "../organisms/FSTF.CardGrid";
import _ from "lodash";

type ProductListing = {
  product: IFSProduct;
  download?: IFSAppDownload;
  documentation?: IFSDocumentation;
};

export const FSTFDashboardLoggedIn = (props: any) => {
  const { isLoggedIn, isSiteAdmin, setPageTitle, dataProvider } =
    useContext(FSAppContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/login");
    } else {
      setPageTitle("Dashboard");
    }
  }, [isLoggedIn]);

  const [dashboard, dashboardLoading, dashboardError] = useAsyncResult(
    async () => {
      if (dataProvider) {
        return await dataProvider.getDashboard();
      }
      return {
        products: [],
        downloads: [],
        documentation: [],
        licensePacks: [],
      };
    },
    [dataProvider],
    {
      products: [],
      downloads: [],
      documentation: [],
      licensePacks: [],
    }
  );

  const productListing = useMemo(() => {
    const productListing: ProductListing[] = dashboard.products.map(
      (product) => {
        const productDownload = dashboard.downloads.find(
          (download) => download.productId === product.id
        );
        const productDoc = dashboard.documentation.find(
          (doc) => doc.productId === product.id
        );

        return {
          product: product,
          download: productDownload,
          documentation: productDoc,
        };
      }
    );

    productListing.sort(
      (ListingA: ProductListing, ListingB: ProductListing) => {
        if (
          ListingA.download !== undefined &&
          ListingB.download !== undefined
        ) {
          return new Date(ListingA.download.createdDate) <
            new Date(ListingB.download.createdDate)
            ? 1
            : -1;
        } else if (
          ListingA.documentation !== undefined &&
          ListingB.documentation !== undefined
        ) {
          return new Date(ListingA.documentation.createdDate) <
            new Date(ListingB.documentation.createdDate)
            ? 1
            : -1;
        }

        return 0;
      }
    );
    return productListing;
  }, [dashboard]);

  const licensePacksSorted = useMemo(() => {
    return _.sortBy(dashboard.licensePacks, ["customerName", "name"]);
  }, [dashboard.licensePacks]);

  useEffect(() => {
    if (dashboardError?.message === "401") {
      navigate("/logout");
    }
  }, [dashboardError]);

  const handleAppDownload = (product: IFSProduct, download: IFSAppDownload) => {
    navigate(`/product/${product.id}/download?autodownload=${download.id}`, {
      state: { ...product },
    });
  };

  const handleDocDownload = (product: IFSProduct, doc: IFSDocumentation) => {
    navigate(`/product/${product.id}/documentation?autodownload=${doc.id}`, {
      state: { ...product },
    });
  };

  const handleLicenseDownload = (licensePack: IFSLicensePack) => {
    navigate(`/licenses/download/${licensePack.id}`);
  };

  const getUploadedTimeText = (dateOne: Date, dateTwo: Date): string => {
    const diff = differenceInDays(dateOne, dateTwo);
    if (diff === 0) {
      return "Today";
    }

    return diff > 1 ? `${diff} days ago` : `${diff} day ago`;
  };

  const getLicenseTimeText = (dateOne: Date, dateTwo: Date): string => {
    const diff = differenceInDays(dateOne, dateTwo);
    if (diff === 0) {
      return "Expires Today";
    }

    return diff > 1 ? `${diff} days left` : `${diff} day left`;
  };

  const error =
    dashboardError && "Unable to retrieve dashboard data - try again later";

  return (
    <div className="k-m-5">
      <StackLayout orientation="vertical" gap={20}>
        <StackLayout orientation="horizontal" gap={20}>
          <Card className="fs-dashboard-card">
            <CardHeader>
              <CardTitle>Latest Product Downloads</CardTitle>
            </CardHeader>
            <div className="k-card-divider" />

            <CardBody>
              {dashboardLoading && (
                <Loader type="infinite-spinner" size="medium" />
              )}

              {error && <p>{error}</p>}

              {productListing.map((item, index) => {
                return (
                  <p key={index}>
                    {item.product.name}&nbsp;
                    {item.download !== undefined && (
                      <Button
                        fillMode={"flat"}
                        svgIcon={fileZipIcon}
                        onClick={() =>
                          handleAppDownload(item.product, item.download!)
                        }
                      >
                        (
                        {getUploadedTimeText(
                          new Date(),
                          new Date(item.download.createdDate)
                        )}
                        )
                      </Button>
                    )}
                    {item.documentation !== undefined && (
                      <Button
                        fillMode={"flat"}
                        svgIcon={filePdfIcon}
                        onClick={() =>
                          handleDocDownload(item.product, item.documentation!)
                        }
                      >
                        (
                        {getUploadedTimeText(
                          new Date(),
                          new Date(item.documentation.createdDate)
                        )}
                        )
                      </Button>
                    )}
                  </p>
                );
              })}
            </CardBody>
          </Card>

          <Card className="fs-dashboard-card">
            <CardHeader>
              <CardTitle>Active Licenses</CardTitle>
            </CardHeader>
            <div className="k-card-divider" />

            <CardBody>
              {dashboardLoading && (
                <Loader type="infinite-spinner" size="medium" />
              )}

              {error && <p>{error}</p>}

              {isSiteAdmin ? (
                <Button
                  onClick={() => navigate("/customers")}
                  themeColor={"primary"}
                  className="k-button k-primary-solid-primary"
                >
                  View Customer List
                </Button>
              ) : (
                dashboard.licensePacks.length == 0 && (
                  <p>No licenses available</p>
                )
              )}

              {!isSiteAdmin &&
                dashboard.licensePacks.length > 0 &&
                dashboard.licensePacks.map((licensePack) => {
                  const diff = getLicenseTimeText(
                    new Date(licensePack.expireDate),
                    new Date()
                  );
                  return (
                    <p>
                      <Button
                        key={licensePack.id}
                        fillMode={"flat"}
                        svgIcon={fileConfigIcon}
                        onClick={() => handleLicenseDownload(licensePack)}
                      >
                        {licensePack.name}
                        &nbsp; ({diff})
                      </Button>
                    </p>
                  );
                })}
            </CardBody>
          </Card>
        </StackLayout>
        <CardGrid
          title="Licenses"
          loading={dashboardLoading}
          data={licensePacksSorted}
          error={error}
        >
          <GridColumn field="customerName" title="Customer" />
          <GridColumn field="name" title="License Name" />
          <GridColumn field="salesAssetReference" title="Asset reference" />
          <GridColumn
            cell={(date) => {
              return (
                <td>
                  {getLicenseTimeText(
                    new Date(date.dataItem.expireDate),
                    new Date()
                  )}
                </td>
              );
            }}
            title="Expiry"
          />
        </CardGrid>
      </StackLayout>
    </div>
  );
};

export default withRouter(FSTFDashboardLoggedIn);
