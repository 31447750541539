import { Button, ButtonGroup, Toolbar } from "@progress/kendo-react-buttons";
import { useContext, useEffect, useState } from "react";
import FSTFConfigurationGroup from "../organisms/FSTF.ConfigurationGroup";
import _ from "lodash";
import { FSTFDefaultTemplate } from "../templates/FSTF.DefaultTemplate";
import { useNavigate } from "react-router-dom";
import { FSAppContext } from "../../providers/FSTF.Context";
import { uploadIcon,downloadIcon } from "@progress/kendo-svg-icons";

export const FSTFConfiguration = (props: any) => {
  
  const { isSiteAdmin,isLoggedIn,user, isDev, setPageTitle} = useContext(FSAppContext);
  const [configData, setConfigData] = useState<{ [key: string]: any }>({});
  const [simpleProperties, setSimpleProperties] = useState<{
    [key: string]: any;
  }>({});
  const [complexProperties, setComplexProperties] = useState<{
    [key: string]: any;
  }>({});
 
  const navigate = useNavigate();
  useEffect(() => {
    if(isDev || isLoggedIn){
      return;
    }
    if (!isSiteAdmin) {
      navigate("/");
    }
  }, [isSiteAdmin, isLoggedIn,navigate]);

  useEffect(() => {
    setPageTitle("Configuration");

    fetch("./assets/templates/_default.json")
      .then((response) => response.json())
      .then((data) => setConfigData(data))
      .catch((error) => console.error("Error loading JSON:", error));
  }, []);

  useEffect(() => {
    processData(configData);
  }, [configData]);
  
  function onExport() {
    //stops exporting any null objects
    const replacer = (key: string, value: any) =>
      value === null ? undefined : value;

    var configJson = JSON.stringify(configData, replacer, 4);
    const blob = new Blob([configJson], { type: "application/json" });

    // Create a temporary link to trigger the download
    const downloadLink = document.createElement("a");
    const url = URL.createObjectURL(blob);
    downloadLink.href = url;
    downloadLink.download = "config.json";

    // Append the link to the document and trigger the download
    document.body.appendChild(downloadLink);
    downloadLink.click();

    // Clean up by removing the link and revoking the Blob URL
    document.body.removeChild(downloadLink);
    URL.revokeObjectURL(url);
  }

  const onImport = () => {
    const fileInput = document.getElementById("fileInput") as HTMLInputElement;
    fileInput.click();
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (file) {
      const reader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>) => {
        const text = e.target?.result;
        try {
          if (typeof text === "string") {
            const newconfig = JSON.parse(text);
            setConfigData(newconfig);
          }
        } catch (error) {
          console.error("Error parsing JSON:", error);
          // Handle errors here
        }
      };
      reader.readAsText(file);
    }
  };

  const handleDataChange = (path: string, value: any) => {
    setConfigData((prevData: any) => {
      const newData = { ...prevData };
      _.set(newData, path, value);
      return newData;
    });
  };

  const handleAddSection = (
    mode: string,
    path: string,
    sectionTitle: string,
    sectionValue: string,
    section?: any
  ) => {
    switch (mode) {
      case "property":
        addNewProperty(path, sectionTitle, sectionValue);
        break;
      case "section":
        addNewSection(path, sectionTitle, sectionValue, section);
        break;
    }
  };

  const addNewSection = (
    path: string,
    sectionTitle: string,
    sectionValue: string,
    section: any
  ) => {
    setConfigData((prevData: any) => {
      const newData = { ...prevData };

      if (_.has(newData, path)) {
        let arr = _.get(newData, path);
        if (_.has(newData, path + "[0].id")) {
          section.id = section.id.replace(
            "{0}",
            arr.length === 0 ? 1 : arr.length + 1
          );
        } else if (_.has(newData, path + "[0].name")) {
          section.name = section.name.replace(
            "{0}",
            arr.length === 0 ? 1 : arr.length + 1
          );
        } else if (sectionTitle !== undefined && sectionTitle.length > 0) {
          if (section.config) {
            section[sectionTitle] = sectionValue;
            delete section.config;
          } else {
            section[sectionTitle] = sectionValue;
          }
        }
        arr.push(section);
      }

      return newData;
    });
  };

  const addNewProperty = (
    path: string,
    sectionTitle: string,
    sectionValue: string
  ) => {
    setConfigData((prevData: any) => {
      let newData = { ...prevData };
      if (_.has(newData, path)) {
        let obj = _.get(newData, path);
        const isArrayItem = Array.isArray(obj);
        const isObjectItem = typeof obj === "object";
        if (isObjectItem && isArrayItem) {
          const data: any = {};
          if (sectionTitle !== undefined && sectionTitle.length > 0) {
            data[sectionTitle] = sectionValue;
          }

          //check to see if this is a complex section
          const isBasicArray =
            Array.isArray(obj) &&
            obj.every(
              (item) =>
                typeof item === "string" ||
                typeof item === "number" ||
                typeof item === "boolean"
            );

          if (isBasicArray) {
            //if its a basic array then just add in the value
            obj.push(sectionValue);
          } else {
            //if its complex array then add it as an object
            obj.push(data);
          }
        } else {
          obj[sectionTitle] = sectionValue;
        }
      } else {
        let newObj: { [key: string]: any } = {};
        newObj[sectionTitle] = sectionValue;
        newData = _.merge(newData, newObj);
      }
      return newData;
    });
  };

  const handleRemoveSection = (path: string) => {
    setConfigData((prevData: any) => {
      let newData = { ...prevData };
      if (_.has(newData, path)) {
        _.unset(newData, path); // Removes item at path

        //when using unset from lodash and unsetting an item in an array, it sets it to null,
        //we then need to remove null/undefined values manually
        const regex = /\[\d\]$/;
        if (regex.test(path)) {
          var obj = _.get(newData, path.replace(regex, ""));
          var filtered = _.filter(obj, (x) => !_.isNull(x) && x !== undefined);
          _.set(newData, path.replace(regex, ""), filtered);
        }
      }

      return newData;
    });
  };

  const handleAddPlugin = (pluginType: string) => {
    fetch(`./assets/templates/${pluginType.toLowerCase()}/_default.json`)
      .then((response) => response.json())
      .then((data) =>
        setConfigData((prevData: any) => {
          const newData = { ...prevData };
          const path = "plugins";

          if (_.has(newData, path)) {
            let arr = _.get(newData, path);

            arr.push(data);
          }

          return newData;
        })
      )
      .catch((error) => console.error("Error loading JSON:", error));
  };

  function processData(data: { [key: string]: any }) {
    let cProperties: { [key: string]: any } = {};
    let sProperties: { [key: string]: any } = {};

    Object.keys(data).forEach((key) => {
      const value = data[key];
      if (typeof value === "object") {
        // For objects and arrays
        cProperties[key] = value;
      } else {
        // For simple values
        sProperties[key] = value;
      }
    });

    setSimpleProperties(sProperties);
    setComplexProperties(cProperties);
  }

  return (
    <FSTFDefaultTemplate>
      <div className="k-d-grid k-m-5 k-gap-5">
        <div className="k-d-flex k-gap-2">
          <Button fillMode="outline" themeColor="light" onClick={onImport}>Import
          </Button>
          <Button  fillMode="outline" themeColor="light" onClick={onExport}>Export
          </Button>
        </div>
      <label htmlFor="fileInput" className="hidden"/>
      <input
        type="file"
        id="fileInput"
        className="hidden"
        onChange={handleFileChange}
        accept=".json"
        title="fileInput"
      />
      <FSTFConfigurationGroup
        configData={configData}
        simpleProperties={simpleProperties}
        complexProperties={complexProperties}
        onDataChange={handleDataChange}
        onAddSection={handleAddSection}
        onRemoveSection={handleRemoveSection}
        onAddPlugin={handleAddPlugin}
      />
      </div>
    </FSTFDefaultTemplate>
  );
};
