import { FSTFDataProvider } from "../../providers/FSTF.DataProvider";
import { FSTFDefaultTemplate } from "../templates/FSTF.DefaultTemplate";
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Card,
  CardActions,
  CardBody,
  CardHeader,
  CardImage,
  CardTitle,
  StackLayout,
} from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import IFSProduct from "../../types/IFSProduct";
import { fileAddIcon } from "@progress/kendo-svg-icons";
import {
  FormRenderProps,
  FormElement,
  Field,
  Form,
  FieldRenderProps,
  FieldWrapper,
} from "@progress/kendo-react-form";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Input, TextArea } from "@progress/kendo-react-inputs";
import { Label, Hint, Error } from "@progress/kendo-react-labels";
import { Upload } from "@progress/kendo-react-upload";
import { FSAppContext } from "../../providers/FSTF.Context";

const allowedFileExtensions = [".png", ".jpg", ".jpeg"];

const AppImgUpload = (fieldRenderProps: FieldRenderProps) => {
  const {
    value,
    id,
    optional,
    label,
    hint,
    validationMessage,
    touched,
    ...others
  } = fieldRenderProps;

  const showValidationMessage: string | false | null =
    touched && validationMessage;
  const showHint: boolean = !showValidationMessage && hint;
  const hintId: string = showHint ? `${id}_hint` : "";
  const errorId: string = showValidationMessage ? `${id}_error` : "";
  const labelId: string = label ? `${id}_label` : "";

  const onChangeHandler = (event: any) => {
    fieldRenderProps.onChange({ value: event.newState });
  };
  const onRemoveHandler = (event: any) => {
    fieldRenderProps.onChange({ value: event.newState });
  };

  return (
    <FieldWrapper>
      <Label
        id={labelId}
        editorId={id}
        optional={optional}
        className="k-form-label"
      >
        {label}
      </Label>
      <div className={"k-form-field-wrap"}>
        <Upload
          restrictions={{ allowedExtensions: allowedFileExtensions }}
          id={id}
          autoUpload={false}
          showActionButtons={false}
          multiple={false}
          files={value}
          onAdd={onChangeHandler}
          onRemove={onRemoveHandler}
          {...others}
        />
        {showHint && <Hint id={hintId}>{hint}</Hint>}
        {showValidationMessage && (
          <Error id={errorId}>{validationMessage}</Error>
        )}
      </div>
    </FieldWrapper>
  );
};

const AppNameInputField = (fieldRenderProps: FieldRenderProps) => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    hint,
    wrapperStyle,
    ...others
  } = fieldRenderProps;
  const editorRef = useRef<any>(null);
  const showValidationMessage: string | false | null =
    touched && validationMessage;
  const showHint: boolean = !showValidationMessage && hint;
  const hintId: string = showHint ? `${id}_hint` : "";
  const errorId: string = showValidationMessage ? `${id}_error` : "";
  const labelId: string = label ? `${id}_label` : "";

  return (
    <FieldWrapper style={wrapperStyle}>
      <Label
        id={labelId}
        editorRef={editorRef}
        editorId={id}
        editorValid={valid}
        className="k-form-label"
      >
        {label}
      </Label>
      <div className={"k-form-field-wrap"}>
        <Input ref={editorRef} valid={valid} id={id} {...others} />
        {showHint && <Hint id={hintId}>{hint}</Hint>}
        {showValidationMessage && (
          <Error id={errorId}>{validationMessage}</Error>
        )}
      </div>
    </FieldWrapper>
  );
};

const AppDescriptionField = (fieldRenderProps: FieldRenderProps) => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    hint,
    wrapperStyle,
    ...others
  } = fieldRenderProps;
  const editorRef = useRef<any>(null);
  const showValidationMessage: string | false | null =
    touched && validationMessage;
  const showHint: boolean = !showValidationMessage && hint;
  const hintId: string = showHint ? `${id}_hint` : "";
  const errorId: string = showValidationMessage ? `${id}_error` : "";
  const labelId: string = label ? `${id}_label` : "";

  return (
    <FieldWrapper style={wrapperStyle}>
      <Label
        id={labelId}
        editorRef={editorRef}
        editorId={id}
        editorValid={valid}
        className="k-form-label"
      >
        {label}
      </Label>
      <div className={"k-form-field-wrap"}>
        <TextArea ref={editorRef} valid={valid} id={id} {...others} />
        {showHint && <Hint id={hintId}>{hint}</Hint>}
        {showValidationMessage && (
          <Error id={errorId}>{validationMessage}</Error>
        )}
      </div>
    </FieldWrapper>
  );
};

export const FSTFProducts = (props: any) => {
  const navigate = useNavigate();

  const { user, dataProvider, setPageTitle, isLoggedIn } =
    useContext(FSAppContext);
  const [addVisible, setAddProductVisible] = useState<boolean>(false);
  const [addProductError, setAddProductError] = useState<String>();
  const [providerError, setProviderError] = useState<String>();
  const [products, setProducts] = useState<IFSProduct[]>([]);

  const provider = dataProvider;

  const handleClick = (p: IFSProduct) => {
    navigate(`/product/${p.id}`, { state: { ...p } });
  };

  useEffect(() => {
    if (dataProvider) {
      provider
        ?.getProducts()
        .then((data) => {
          data.sort((a: IFSProduct, b: IFSProduct) => {
            return new Date(a.createdDate) < new Date(b.createdDate) ? 1 : -1;
          });
          setProviderError(undefined);
          setProducts(data);
        })
        .catch((error) => {
          setProviderError("Unable to retrieve product list");
        });
    }
  }, []);

  const canAddProduct =
    user?.siteRoles?.find((role) => role?.toLowerCase() === "siteadmin") !==
    undefined;

  const toggleAddProductVisibility = () => {
    setAddProductVisible(!addVisible);
    setAddProductError(undefined);
  };

  const appNameValidator = (value: string) =>
    !value ? "Application name is required" : "";
  const appImgValidator = (value: any) =>
    !value ? "Application image is required" : "";
  const appDescValidator = (value: string) =>
    !value ? "Description is required" : "";

  const handleAddApp = (e: any) => {
    if (e.appimg && e.appimg.length > 0) {
      const extAllowed = allowedFileExtensions.includes(
        e.appimg[0].extension.toLowerCase()
      );
      if (!extAllowed) {
        setAddProductError("The file extension was not valid");
      } else {
        const theFile = e.appimg[0].getRawFile() as File;
        const reader = new FileReader();

        reader.onloadend = () => {
          const newProd = {
            name: e.appname,
            description: e.appdescription,
            image: String(reader.result),
          } as IFSProduct;
          if (provider) {
            provider
              .addProduct(newProd)
              .then((response) => {
                setProducts([response, ...products]);
                toggleAddProductVisibility();
                setAddProductError(undefined);
              })
              .catch((providerError) => {
                setAddProductError(
                  "An error occurred while adding product. Please try again later"
                );
              });
          }
        };
        reader.readAsDataURL(theFile);
      }
    }
  };

  return (
    <FSTFDefaultTemplate>
      <div id="product" className="k-m-5">
        {canAddProduct && (
          <div className="k-mb-6">
            <Button themeColor={"primary"} className="k-button k-primary-solid-primary"
              style={{ marginTop: 10, marginBottom: 10 }}
              onClick={toggleAddProductVisibility}
            >
              Add Product
            </Button>
          </div>
        )}

        {canAddProduct && addVisible && (
          <div >
            <Dialog className="fs-add-product"
              title={"Add New Application"}
              onClose={toggleAddProductVisibility} 
            >
              {addProductError !== undefined && (
                <div className="error">{addProductError}</div>
              )}

              <Form
                onSubmit={handleAddApp}
                render={(formRenderProps: FormRenderProps) => (
                  <FormElement style={{ width: 500 }}>
                    <Field
                      id={"appname"}
                      name={"appname"}
                      label={"Application Name"}
                      component={AppNameInputField}
                      validator={appNameValidator}
                    />

                    <Field
                      id={"appdescription"}
                      name={"appdescription"}
                      label={"Description"}
                      placeholder="Type description here ..."
                      component={AppDescriptionField}
                      validator={appDescValidator}
                    />

                    <Field
                      id={"appimg"}
                      name={"appimg"}
                      label={`Upload (allowed extensions ${allowedFileExtensions.join(
                        ", "
                      )})`}
                      component={AppImgUpload}
                      validator={appImgValidator}
                    />
                    <div className="k-d-flex k-gap-2 k-mt-5 k-mb-5 k-justify-content-end">
                      <Button
                        themeColor={"light"}  onClick={toggleAddProductVisibility}>Cancel</Button>
                      <Button
                        themeColor={"primary"} className="k-button k-primary-solid-primary"
                        type={"submit"}
                        disabled={!formRenderProps.allowSubmit}>
                        Submit
                      </Button></div>
                  </FormElement>
                )}
              />
            </Dialog>
          </div>
        )}

        <StackLayout orientation="horizontal" gap={20}  >
          {providerError !== undefined ? (
            <div className="error">{providerError}</div>
          ) : (
            products.map((p, index) => {
              return (
                <Card key={p.id} className="fs-product-card">
                  <CardHeader>
                    <CardTitle>{p.name}</CardTitle>
                  </CardHeader>
                  <div className="k-card-divider"></div>
                  <CardBody>
                    <StackLayout orientation="vertical" gap={20}>
                      <CardImage src={p.image} />
                      <div>{p.description}</div>
                    </StackLayout>
                  </CardBody>

                  <CardActions>
                    <Button themeColor={"primary"} className="k-button k-primary-solid-primary"
                      onClick={() => handleClick(p)}
                    >
                      Downloads
                    </Button>
                  </CardActions>
                </Card>
              );
            })
          )}
        </StackLayout>
      </div>
    </FSTFDefaultTemplate>
  );
};
