import IFSLicense from "../../types/IFSLicense";

export const FSTFLicenseItem = (props: IFSLicense) => {
  return (    <>   
        <p className="fs-reference">{props.reference}</p>
        <p className="fs-name">{props.name}</p>
        <p className="fs-description">{props.description}</p>
        </>
  );
};
