import "./App.scss";
import { HashRouter } from "react-router-dom";
import { GridLayout } from "@progress/kendo-react-layout";
import FSTFAppContextProvider from "./providers/FSTF.AppContextProvider";
import "reflect-metadata";
import { FSTFHeader } from "./components/organisms/FSTF.Header";
import { FSTFFooter } from "./components/organisms/FSTF.Footer";
//App Insights with ReactJS: https://docs.microsoft.com/azure/azure-monitor/app/javascript-react-plugin
import { FSDebugOutputPanel } from "./components/molecules/FSTF.Debug.Output";
import { WithAppInsights } from "./components/atoms/FSTF.AppInsightsWrapper";
import "@progress/kendo-svg-icons";
import { IconsContext } from "@progress/kendo-react-common";
import { FSEnvironmentProvider } from "./providers/FSTF.EnvironmentProvider";
import { FSTFLogo } from "./components/organisms/FSTF.Logo";
import { FSTFMain } from "./components/organisms/FSTF.Main";

function App() {
  return WithAppInsights(
    <>
      <IconsContext.Provider value={{ type: "svg" }}>
        <FSEnvironmentProvider>
          <FSTFAppContextProvider>
            <FSDebugOutputPanel />
            <HashRouter>
              <GridLayout
                style={{ flex: 1 }}
                rows={[
                  {
                    height: "auto",
                  },
                  {
                    height: "1fr",
                  },
                  {
                    height: "auto",
                  },
                ]}
                cols={[
                  {
                    width: "274.91px",
                  },
                  {
                    width: "1fr",
                  },
                ]}
              >
                <FSTFLogo id="logo" row={1} col={1} />
                <FSTFHeader id="title" row={1} col={2} />
                <FSTFMain row={2} />
                <FSTFFooter row={3} col={1} colSpan={2} />
              </GridLayout>
            </HashRouter>
          </FSTFAppContextProvider>
        </FSEnvironmentProvider>
      </IconsContext.Provider>
    </>
  );
}

export default App;
