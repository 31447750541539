import React, { useContext, useEffect, useState } from "react";
import {
  Form,
  Field,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";

import { Input } from "@progress/kendo-react-inputs";
import { Card, CardBody, StackLayout } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import { Label } from "@progress/kendo-react-labels";
import { FSAppContext } from "../../providers/FSTF.Context";
import { FSTFDefaultTemplate } from "../templates/FSTF.DefaultTemplate";
import { useNavigate } from "react-router-dom";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";


export const FSTFResetPasswordReq = () => {
  const { dataProvider, setPageTitle } = useContext(FSAppContext);
  const [message, setMessage] = useState('');
  const [showDialog, setShowDialog] = useState(false);

  useEffect(() => {
    setPageTitle("Password Reset Request");
  }, []);

  const navigate = useNavigate();
  const handleSubmit = async (e: any) => {
    dataProvider?.resetPasswordRequest(e.email).then((data) => {
      setMessage(`Password reset link has been sent to ${e.email}. Please check your email.`);
      setShowDialog(true);
    }).catch((error) => {
      console.error("Unable to send reset password link" + error.message);
      setMessage(`Unable to send reset password link. Please try again later.`);
      setShowDialog(true);
    });
  };

  const handleCloseDialog = () => {
    setShowDialog(false);
    navigate('/login');
  };

  const handleBack = () => {
    navigate('/login');
  };
  return (
    <FSTFDefaultTemplate>
      {showDialog && (
        <Dialog title={"Password reset"} onClose={handleCloseDialog} className="fs-dialogbox">
          <p>{message}</p>
          <DialogActionsBar>
            <Button themeColor={"primary"} className="k-button k-primary-solid-primary" onClick={handleCloseDialog}>OK</Button>
          </DialogActionsBar>
        </Dialog>
      )}
      <StackLayout orientation="horizontal" className="app-login">
        <Card>
          <CardBody>
            <Form
              onSubmit={handleSubmit}
              render={(formRenderProps: FormRenderProps) => {
                return (
                  <FormElement>
                    <p className="hint">
                      Please enter registered email address to reset password
                    </p>
                    <fieldset className={"k-form-fieldset"}>
                      <div className="k-mb-4">
                        <Label>Email</Label>
                        <Field name={"email"} component={Input} type="email" required={true} />
                      </div>
                    </fieldset>
                    <div className="fs-login-btngroup">
                      <Button themeColor={"primary"} className="k-button k-primary-solid-primary" type={"submit"}>Reset</Button>
                      <Button themeColor={"light"} onClick={handleBack}>Back</Button>
                    </div>
                  </FormElement>
                );
              }}
            />
          </CardBody>
        </Card>
      </StackLayout>
    </FSTFDefaultTemplate>
  );
};