import React, { useContext, useEffect, useState } from "react";
import {
  FSAppContext,
  IFSTFAppContextProps,
} from "../../providers/FSTF.Context";
import {
  AppBar,
  AppBarSection,
  AppBarSpacer,
  GridLayoutItem,
  GridLayoutItemProps,
} from "@progress/kendo-react-layout";
import { BadgeContainer } from "@progress/kendo-react-indicators";
import { SvgIcon } from "@progress/kendo-react-common";
import { bellIcon } from "@progress/kendo-svg-icons";
import IFSMessage from "../../types/IFSMessage";
import IFSCustomerUsers from "../../types/IFSCustomerUsers";
import { Button } from "@progress/kendo-react-buttons";
import { UserMessageForm } from "../molecules/FSTF.MessagingForm";
import IFSCustomer from "../../types/IFSCustomer";
import { useNavigate } from "react-router-dom";

export const FSTFHeader = (props: GridLayoutItemProps) => {
  const { user, isLoggedIn, dataProvider } =
    useContext<IFSTFAppContextProps>(FSAppContext);
  const [showMessagesWindow, setShowMessagesWindow] = useState<boolean>(false);
  const [userCustomers, setUserCustomers] = useState<IFSCustomer[]>([]);
  const [showBell, setShowBell] = useState<boolean>(false);
  const navigate = useNavigate();
  const provider = dataProvider;

  useEffect(() => {
    if (isLoggedIn && user.id) {
      //get list of customers assigned to this user with valid roles
      const desiredRoles = ["viewer", "accountmanager"];
      provider
        ?.getUserCustomers(user.id)
        .then((data: IFSCustomerUsers[]) => {
          const filtered = data.filter(
            (customeruser) =>
              customeruser.active !== false &&
              customeruser.roles?.some((role) =>
                desiredRoles.includes(role.toLowerCase())
              )
          );

          setShowBell(filtered.length > 0);

          return filtered;
        })
        .then((filteredCustomerUsersList: IFSCustomerUsers[]) => {
          const getCustomerAccount = (
            customerUser: IFSCustomerUsers
          ): Promise<IFSCustomer> => {
            return new Promise((resolve, reject) => {
              provider
                .getCustomer(customerUser.customerId!)
                .then((c) => {
                  resolve(c);
                })
                .catch((error) => {
                  reject(error);
                });
            });
          };

          Promise.all(
            filteredCustomerUsersList.map((customer) =>
              getCustomerAccount(customer)
            )
          ).then((allCustomers2dArray) => {
            //Promise.all returns 2D array. Each array of the top layer contains the result of each promise.
            //allCustomers[0] is an array and allCustomers[0][0] is the actual IFSCustomer object.
            //allCustomers[1] is an array and allCustomers[1][0] is the actual IFSCustomer object
            //Extract the objects from their nested layers and construct 1D array to save in state
            const realCustomerArray: IFSCustomer[] = allCustomers2dArray.map(
              (childArray) => {
                const resultsArray = childArray as IFSCustomer[];
                return resultsArray[0];
              }
            );

            setUserCustomers(realCustomerArray);
          });
        })
        .catch((error) => {
          //TODO: Error handling
        });
    }
  }, [user]);

  let userFullName = "";
  let userEmail="";
  if (user.firstName) {
    userFullName = user.firstName;
  }
  if (userFullName && user.lastName) {
    userFullName = userFullName + " " + user.lastName;
  }
  if (userFullName && user.siteRoles) {
    userFullName = userFullName + " - " + user.siteRoles.join(", ");
  }
  if(user.email){
    userEmail=user.email;
  }
  
  const handleBadgeClick = () => {
    setShowMessagesWindow(true);
  };

  const handleCloseMessages = () => {
    setShowMessagesWindow(false);
  };

  const handleSendMessage = async (
    message: IFSMessage
  ): Promise<IFSMessage> => {
    const newMessage = {
      ...message,
      createdBy: user.userName!,
      isUnread: true,
    } as IFSMessage;

    return new Promise(function (resolve, reject) {
      provider
        ?.sendCustomerMessage(newMessage.customerId, newMessage)
        .then((resp) => {
          resolve(resp);
        })
        .catch((error) => {
          reject("Unable to send message, try again later");
        });
    });
  };

  const handleLoadMessagesForCustomer = (
    customer: IFSCustomer
  ): Promise<IFSMessage[]> => {
    return new Promise((resolve, reject) => {
      provider
        ?.getCustomerMessages(customer.id!)
        .then((messages) => resolve(messages))
        .catch((error) =>
          reject(`Unable to load messages for ${customer.name!}`)
        );
    });
  };

  const { className, ...gridProps } = props;
  return (
    <GridLayoutItem {...gridProps}>
      <AppBar className={className}>
        <AppBarSection>
          <strong className="title">SECUREpane</strong>
        </AppBarSection>
        <AppBarSpacer style={{ width: 4 }} />
        {isLoggedIn && showBell && (
          <AppBarSection className="actions">
            <Button
              title={`Unread messages`}
              onClick={handleBadgeClick}
              fillMode={"flat"}
            >
              <BadgeContainer>
                <SvgIcon icon={bellIcon} />
              </BadgeContainer>
            </Button>
          </AppBarSection>
        )}
        {showMessagesWindow && (
          <UserMessageForm
            handleLoadMessagesForCustomer={handleLoadMessagesForCustomer}
            title="Messages"
            handleSubmit={(message) => {
              return handleSendMessage(message);
            }}
            handleClose={() => handleCloseMessages()}
            userAccessibleCustomers={userCustomers}
          />
        )}
        {isLoggedIn && (<AppBarSection className="fs-email">
    <span>
      <strong>{userEmail}</strong>
    </span>
  </AppBarSection>)}
      </AppBar>
    </GridLayoutItem>
  );
};
