import {Checkbox, CheckboxChangeEvent,TextArea} from "@progress/kendo-react-inputs";
import { FSTFDefaultTemplate } from "../templates/FSTF.DefaultTemplate";
import { Card, StackLayout } from "@progress/kendo-react-layout";
import { useContext, useEffect, MouseEvent, useReducer, useState } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { downloadIcon } from "@progress/kendo-svg-icons";
import { useNavigate, useParams } from "react-router-dom";
import { FSAppContext } from "../../providers/FSTF.Context";
import IFSLicenseAgreement from "../../types/IFSLicenseAgreement";

export const FSTFDownloadLicenseEula = (props: any) => {

  const checkBoxKeys = ["eula", "retainDetails", "damageResponsibility"];

  type ReducerState = {
    loading: boolean;
    providerError: string | undefined;
    conditionsAgreed: string[];
    action: string;
    actionMeta:string | boolean;
  }

  const reducer = (state: ReducerState, action: ReducerState) : ReducerState => {
    switch (action.action) {
      
      case "loading":
        return { ...state, providerError: undefined, loading: action.actionMeta as boolean };

      case "checked":
        return { ...state, conditionsAgreed: [...state.conditionsAgreed, action.actionMeta as string]};

      case "unchecked":
        return { ...state, conditionsAgreed: state.conditionsAgreed.filter(c => c !== action.actionMeta)};
          
      case "reset":
        return {...state, providerError: undefined, conditionsAgreed: [], loading: false};

      case "providerError":
        return {...state, loading: false, providerError: action.actionMeta as string}

      default:
        return state;
    }
  };

  const [ downloadRequestState, dispatch ] = useReducer(reducer, 
    { loading: true, providerError: undefined, actionMeta: "", conditionsAgreed: [], action: ""});

  const navigate = useNavigate();
  const { dataProvider, setPageTitle } = useContext(FSAppContext);
  const { licenseid } = useParams();
  const [eula, setEula] = useState<string>("");

  useEffect(() => {
    setPageTitle("End User License Agreement (EULA)");

    const checkagreement : IFSLicenseAgreement = {
      licensePackId: licenseid!,
      agreementDateTime: ""
    }
    
    dispatch({...downloadRequestState, action: "loading", actionMeta: true});

    dataProvider?.checkLicenseAgreement(checkagreement).then((data) => {
      dispatch({...downloadRequestState, action: "loading", actionMeta: false});

      if (data == 403) {
        navigate('/');
      }

      //404 == needs to agree to license
      //200 == redirect to download
      else if (data == 200) {

        //has existing agreement - redirect to download
        navigate(`/licenses/download/${licenseid}`);
      }

      else if (data == 404) {
         //404 - needs to agree to eula
         //get eula from api
         dataProvider.getEula().then((data) => {
          setEula(data);
         }).catch((error) => {
          dispatch({...downloadRequestState, action: "providerError", 
            actionMeta: "Unable to retrieve EULA - try again later"});
         });
      }

      else {
        dispatch({...downloadRequestState, action: "providerError", 
          actionMeta: "Unable to check for license agreement status - try again later"});
      }

    }).catch((error) => {
      dispatch({...downloadRequestState, action: "providerError", 
        actionMeta: "Unable to check for license agreement status - try again later"})
    });

  }, [licenseid]);

  const checkBoxChanged = (e: CheckboxChangeEvent) => {
    dispatch({
      ...downloadRequestState, action: e.value ? "checked" : "unchecked", actionMeta: e.target.name as string, 
    });
  };

  const downloadLicensePackOnClick = (e: MouseEvent<HTMLButtonElement>): void => {
    if (dataProvider === undefined) {
      return;
    }

    const licenseAgreement : IFSLicenseAgreement = {
      licensePackId: licenseid!,
      agreementDateTime: new Date().toISOString(),
      notes: checkBoxKeys.map((key) => key + ":" + (downloadRequestState.conditionsAgreed.indexOf(key) !== -1 ? "true" : "false")).join(";")
    }

    dataProvider.addLicenseAgreement(licenseAgreement).then((data) => {
        navigate(`/licenses/download/${licenseid}`);
      }).catch((error) => {
        dispatch({ ...downloadRequestState, action: "providerError", actionMeta: "Unable to save license agreement - try again later" });
      });
  };

  return (
    <FSTFDefaultTemplate>        
      <div className="k-m-5">
          <Card className="fs-product-card">
          <h1>4-Secure End User License Agreement (EULA)</h1>
          {downloadRequestState.loading === true && <h3>Checking your License Agreement status...</h3>}

          {downloadRequestState.providerError !== undefined && (
            <p className="error">{downloadRequestState.providerError}</p>
          )}

          {downloadRequestState.loading === false && downloadRequestState.providerError === undefined && (
          <>
          <h3>Agree to the EULA to download your License.</h3>
          <TextArea rows={50} value={eula} readOnly={true} />
          <br />
          <br />
          <StackLayout orientation="vertical">
            <Checkbox
              name={checkBoxKeys[0]}
              checked={downloadRequestState.conditionsAgreed.indexOf(checkBoxKeys[0]) !== -1}
              onChange={(e) => {checkBoxChanged(e);}}
              style={{backgroundColor: " #2b377b" , border:"none"}}
              label="I agree to the terms and conditions of the EULA"
            />
            <Checkbox
              name={checkBoxKeys[1]}
              checked={downloadRequestState.conditionsAgreed.indexOf(checkBoxKeys[1]) !== -1}
              onChange={(e) => {checkBoxChanged(e);}}
              label="I agree 4-Secure can retain my details"              
              style={{backgroundColor: "#2b377b" , border:"none"}}
            />
            <Checkbox
              name={checkBoxKeys[2]}
              checked={downloadRequestState.conditionsAgreed.indexOf(checkBoxKeys[2]) !== -1}
              onChange={(e) => {checkBoxChanged(e);}}
              label="I agree any damage arising is not the responsibility of 4-Secure"              
              style={{backgroundColor: " #2b377b", border:"none"}}
            />
          </StackLayout>
          {downloadRequestState.conditionsAgreed.length == checkBoxKeys.length && (
            <>
              <br />
              <Button
                themeColor={"primary"}
                className="k-button k-primary-solid-primary"
                svgIcon={downloadIcon}
                style={{ marginTop: 10, marginBottom: 10 }}
                onClick={(e) => downloadLicensePackOnClick(e)}>
                Download License
              </Button>
            </>
          )}
          </>
        )}
        </Card>
        </div>
    </FSTFDefaultTemplate>
  )
}
