import { useContext } from "react";
import { FSAppContext } from "../../providers/FSTF.Context";
import { IFSLicensePack } from "../../types/IFSLicensePack";
import { FSTFLicenseItem } from "./FSTF.LicenseItem";
import { StackLayout, } from "@progress/kendo-react-layout";

export const FSTFLicensePack = (props: IFSLicensePack) => {

  const { isSiteAdmin } = useContext(FSAppContext);

  return (
    <StackLayout orientation="vertical" className="fs-licensepack-view">
      <div>
        <h3>
          Reference: {props.siteReference} | Created: {new Date(props.creationDate).toDateString()}|
          Expires: {new Date(props.expireDate).toDateString()}
        </h3>
      </div>
      <div className="f-license-content">
        {props.licenses === undefined ? (
          <div>No Licenses/loading</div>
        ) : (
          props.licenses.map((license) => {
            return <FSTFLicenseItem key={license.id} {...license} />;
          })
        )}
      </div>

      <StackLayout orientation="horizontal" className="fs-license-metadata">
        {isSiteAdmin && (
          <div className="fs-metadata f-license-content">
            <div className="k-card-divider"></div>
            <p>Sales Asset Reference: {props.salesAssetReference}</p>
            <p>Opportunity ID: {props.salesCustomerReference}</p>
            <p>Origin License Sets: {props.salesNotes}</p>
            <p>License ID: {props.id}</p>
          </div>
        )}
      </StackLayout>
    </StackLayout>
  );
};
