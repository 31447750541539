import React, { useContext, useEffect, useState } from "react";
import {
  Form,
  Field,
  FormElement,
  FormRenderProps,
  FieldWrapper
} from "@progress/kendo-react-form";

import { Input } from "@progress/kendo-react-inputs";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  StackLayout,
} from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import { FSAppContext, defaultAppContext } from "../../providers/FSTF.Context";
import { useNavigate } from "react-router-dom";
import { FSTFAuthProvider } from "../../providers/FSTF.AuthProvider";
import { FSTFDefaultTemplate } from "../templates/FSTF.DefaultTemplate";
import IFSCustomerUsers from "../../types/IFSCustomerUsers";
import { SITEPERMISSION } from "../../types/FSPermissionsDictionary";
import { Label } from "@progress/kendo-react-labels";

export const FSTFLogin = () => {
  const {
    setIsDevValue,
    setIsSiteAdminValue,
    setIsValidCustomerAccessValue,
    setIsLoggedInValue,
    setPageTitle,
    setCurrentPageValue,
  } = useContext(FSAppContext);
  const { dataProvider } = useContext(FSAppContext);
  const [loginFailed, setLoginFailed] = useState(false);

  const { setTokenValue, setUserValue, user, useFakeAuth } =
    useContext(FSAppContext);

  const navigate = useNavigate();

  const auth = new FSTFAuthProvider(useFakeAuth);

  useEffect(() => {
    setPageTitle("Login");
  }, []);

  useEffect(() => {
    if (
      setUserValue &&
      setIsSiteAdminValue &&
      setIsDevValue &&
      setIsValidCustomerAccessValue &&
      setIsLoggedInValue
    ) {
      //clear user on entry
      setUserValue(defaultAppContext.user);
      setIsSiteAdminValue(false);
      setIsValidCustomerAccessValue(false);
      setIsDevValue(false);
      setIsLoggedInValue(false);
    }
  }, [
    setUserValue,
    setIsSiteAdminValue,
    setIsDevValue,
    setIsValidCustomerAccessValue,
    setIsLoggedInValue,
  ]);

  const handleResetPasswordRequest = () => {
    navigate("/resetpasswordreq");
  };

  const handleSubmit = async (e: any) => {
    if (loginFailed) {
      setLoginFailed(false);
    }

    auth
      .login(e.username, e.password)
      .then((ud) => {
        //Set up the user state and set the appropriate flags for admin and dev
        if (
          ud !== undefined &&
          setTokenValue !== undefined &&
          setUserValue !== undefined &&
          setIsLoggedInValue !== undefined &&
          setIsSiteAdminValue !== undefined
        ) {
          setTokenValue(ud.token + "");
          setUserValue(ud);
          setIsLoggedInValue(true);

          if (
            setIsSiteAdminValue &&
            setIsDevValue &&
            setIsValidCustomerAccessValue
          ) {
            const isAdmin = ud.siteRoles?.find(
              (r) => r?.toLocaleLowerCase() === SITEPERMISSION.siteadmin
            );
            if (isAdmin) {
              setIsSiteAdminValue(true);
            } else {
              if (ud.id !== undefined) {
                dataProvider
                  ?.getUserCustomers(ud.id)
                  .then((data) => {
                    const filtered = data.filter(
                      (customer) => customer.active !== false
                    );
                    const userRole: IFSCustomerUsers[] = [...filtered];

                    const desiredRoles = [
                      "companyadmin",
                      "companymanager",
                      "CompanyLogin",
                      "companyviewer",
                    ];

                    const hasDesiredRole = userRole.some((item) =>
                      item.roles?.some((role) =>
                        desiredRoles.includes(role.toLowerCase())
                      )
                    );

                    setIsValidCustomerAccessValue(hasDesiredRole);
                  })
                  .catch((error) => {
                    console.error(
                      "Unable to retrieve customers for logged in user" +
                        error.message
                    );
                  });
              } else {
                // Handle the case where ud.id is undefined or not available
                setIsValidCustomerAccessValue(false);
              }
            }
            if (ud.siteRoles?.find((r) => r?.toLowerCase() === "developer")) {
              setIsDevValue(true);
            } else {
              setIsDevValue(false);
            }
          }
          setLoginFailed(false);
          navigate("/dashboard");
          setCurrentPageValue!("");
        } else {
          setLoginFailed(true);
        }
      })
      .catch((error) => {
        setLoginFailed(true);
      });
  };

  return (
    <FSTFDefaultTemplate>
      <StackLayout orientation="horizontal" className="app-login">
        <Card>
          <CardBody>
            <Form
              onSubmit={handleSubmit}
              render={(formRenderProps: FormRenderProps) => {
                return (
                  <FormElement>
                      {loginFailed && (
                        <p className="error-message">
                          *Invalid username or password
                        </p>
                      )}
                      <fieldset className={"k-form-fieldset"}>
                      <div className="k-mb-4">
                        <Label>Username</Label>
                        <Field
                          name={"username"}
                          component={Input}
                        />
                      </div>
                      <div className="k-mb-4">
                        <Label>Password</Label>
                      <Field
                        name={"password"}
                        component={Input}
                        type={"password"}
                      />
                      </div>
                    </fieldset>
                    <br />
                    <div className="fs-login-btngroup">
                      <Button themeColor={"primary"} className="k-button k-primary-solid-primary" type={"submit"}>
                        Login
                      </Button>
                      <Button
                        themeColor={"light"}
                        onClick={handleResetPasswordRequest}
                      >
                        Reset Password
                      </Button>
                    </div>
                  </FormElement>
                );
              }}
            />
          </CardBody>
        </Card>
      </StackLayout>
    </FSTFDefaultTemplate>
  );
};
