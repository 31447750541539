import { DropDownButton } from "@progress/kendo-react-buttons";
import {
  AppBar,
  AppBarSection,
  AppBarSpacer,
} from "@progress/kendo-react-layout";

import {plusIcon} from "@progress/kendo-svg-icons";
import { SvgIcon } from "@progress/kendo-react-common";

interface ConfigPluginBarProps {
  pluginTypes: { [key: string]: { value: string; icon: string } }[];
  onAddPlugin: (pluginType: string) => void;
}

const ConfigPluginBar: React.FC<ConfigPluginBarProps> = ({
  pluginTypes,
  onAddPlugin,
}) => {
  function handleAddNewPlugin(pluginType: string) {
    onAddPlugin(pluginType);
  }

  const items = pluginTypes.map((obj) => {
    var key = Object.keys(obj)[0];
    return (
      <span key={key} style={{color:"black"}} onClick={() => handleAddNewPlugin(key)}>        
        {obj[key].value}
      </span>
    );
  });

  return (
    <div key="pluginbar" className="k-d-flex k-justify-content-start k-gap-4 k-m-4">
      <h2>TrustedFilter™ Plugins</h2> 
      <div className="k-mt-4">  
      <DropDownButton  text="Add New" items={items} fillMode="outline" themeColor="light"/>
    </div>
    </div>
        
  );
};

export default ConfigPluginBar;
