import { GridLayoutItem } from "@progress/kendo-react-layout";
import FSTFNavigation from "./FSTF.Navigation";
import { useContext } from "react";
import { FSAppContext } from "../../providers/FSTF.Context";
import FSTFRoutes from "./FSTF.Routes";

export const FSTFMain = ({ row = 1, col = 1 }) => {
  const { showNavigation } = useContext(FSAppContext);

  const colRow = col + (showNavigation ? 1 : 0);
  const routesColSpan = showNavigation ? 1 : 2;

  return (
    <>
      {showNavigation && <FSTFNavigation id="navigation" row={row} col={1} />}
      <GridLayoutItem
        id="content"
        row={row}
        col={colRow}
        colSpan={routesColSpan}
        className="app-main"
      >
        <FSTFRoutes />
      </GridLayoutItem>
    </>
  );
};
