import React from "react";
import { Button } from "@progress/kendo-react-buttons";
import {trashIcon} from "@progress/kendo-svg-icons";

interface ConfigRemoveSectionButtonProps {
  path: string;
  inline?: boolean;
  title?: string;
  onClick: (path: string) => void;
}

const removeButtonStyle = (inline: boolean) => {
  return inline
    ? {
        marginTop: "1px",
        marginLeft: "20px",
        height: "39px",
      }
    : {};
};

const ConfigRemoveSectionButton: React.FC<ConfigRemoveSectionButtonProps> = ({
  path,
  inline = false,
  title = "Section",
  onClick: handleClick,
}) => (
  <Button
  svgIcon={trashIcon}
  themeColor={"secondary"}  className="k-button k-primary-solid-secondary"   
    title={inline ? "Remove Property" : `Remove Section`}
    onClick={() => handleClick(path)}
    size={"small"}
    style={removeButtonStyle(inline)}
  >
    {inline ? "" : `Remove ${title}`}
  </Button>
);

export default ConfigRemoveSectionButton;
