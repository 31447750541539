import { FSTFDefaultTemplate } from "../templates/FSTF.DefaultTemplate";
import {
  Field,
  FieldWrapper,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { Label } from "@progress/kendo-react-labels";
import { Button } from "@progress/kendo-react-buttons";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FSAppContext } from "../../providers/FSTF.Context";
import {
  Card,
  PanelBar,
  PanelBarItem,
  StackLayout,
} from "@progress/kendo-react-layout";
import { LicenceTextInputField } from "../atoms/FSTF.LicenseTextInputField";
import { LicenceDateInputField } from "../atoms/FSTF.LicenseDateInputField";
import IFSCustomer from "../../types/IFSCustomer";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { add } from "date-fns";
import { IFSLicenseRequest } from "../../types/IFSLicenseRequest";
import {
  ListBox,
  ListBoxToolbar,
  processListBoxData,
  ListBoxToolbarClickEvent,
  ListBoxItemClickEvent,
} from "@progress/kendo-react-listbox";
import { TextBox } from "@progress/kendo-react-inputs";
import { IFSLicenseItem } from "../../types/IFSLicenseItem";
import { LicenseTextAreaInputField } from "../atoms/FSTF.LicenseTextAreaInputField";

const CustomerDropDown = (fieldRenderProps: any) => {
  const { id, label, onChange, customerId, ...others } = fieldRenderProps;

  const customers = others.customers as IFSCustomer[];
  const defaultSelection =
    customerId == undefined
      ? undefined
      : customers.find((customer) => customer.id == customerId);

  return (
    <FieldWrapper>
      <Label editorId={id}>{label}</Label>
      <div>
        <DropDownList
          id={id}
          data={customers.map((customer) => customer.name)}
          onChange={(e) =>
            onChange(customers.find((customer) => customer.name == e.value))
          }
          defaultValue={defaultSelection?.name}
          disabled={defaultSelection !== undefined}
        />
      </div>
    </FieldWrapper>
  );
};

export const FSTFAddLicenses = (props: any) => {
  //proprties of a single item in a ListBox
  type ListBoxItemProps = {
    id: string;
    name: string; //This MUST match LISTBOX_TEXT_FIELD below so ListBox can identify which prop is the item display name
    selected: boolean;
    items: IFSLicenseItem[];
  };

  //The state of the ListBoxes
  type ListBoxState = {
    availableLicenses: ListBoxItemProps[]; //this field MUST match AVAILABLE_LICENSES below for ListBox to track click state
    selectedLicenses: ListBoxItemProps[]; //this field MUST match SELECTED_LICENSES below for ListBox to track click state
    draggedItem: any;
  };

  //These fields are used by ListBox to identify what properties
  //to use when handling changes in its internal code
  const SELECTED_FIELD = "selected"; //name of prop which remembers if an item as selected or not
  const AVAILABLE_LICENSES = "availableLicenses"; //name of prop which contains items in the first ListBox
  const SELECTED_LICENSES = "selectedLicenses"; //name of prop which contains items in the second ListBox
  const LISTBOX_TEXT_FIELD = "name"; //prop in ListBoxItemProps which contains the item display name

  //default dates used in various form fields
  const defaultStartDate = new Date();
  const defaultEndDate = add(defaultStartDate, { months: 1 });

  const {
    isSiteAdmin,
    user,
    isValidCustomerAccess,
    useFakeData,
    dataProvider,
    setPageTitle,
  } = useContext(FSAppContext);
  const [providerError, setProviderError] = useState<string>();
  const [formError, setFormError] = useState<{
    field: string;
    error: string;
  }>();
  const [customers, setCustomers] = useState<IFSCustomer[]>([]);
  const [currentCustomer, setCurrentCustomer] = useState<IFSCustomer>();
  const navigate = useNavigate();
  const [formKey, setFormKey] = useState<number>(1);
  const formTextValidator = (value: string) =>
    !value ? "This field is required" : "";
  const [listBoxSelectionState, setListBoxSelectionState] =
    useState<ListBoxState>({
      availableLicenses: [],
      selectedLicenses: [],
      draggedItem: {},
    });

  if (!isValidCustomerAccess && !isSiteAdmin) {
    navigate(`/`);
  }

  const { customerId } = useParams();

  //useEffect which runs if customerId changes. This is needed to avoid a bug scenario:
  //1) Navigate from customer licenses view to this page
  //2) Remove customerId from URL bar and press enter
  //3) customerId is still the ID which was in URL bar
  useEffect(() => {
    dataProvider
      ?.getCustomers()
      .then((data) => {
        setCustomers(data);
        if (customerId !== undefined) {
          setCurrentCustomer(
            data.find((customer) => customer.id == customerId)
          );
        } else setCurrentCustomer(undefined);
      })
      .catch((error) => {
        setProviderError("Unable to load customers - try again later");
      });
  }, [customerId]);

  useEffect(() => {
    dataProvider
      ?.getLicenseSets()
      .then((data) => {
        setListBoxSelectionState({
          availableLicenses: data.map((set) => ({
            name: set.name,
            selected: false,
            id: set.id,
            items: set.licenseItems!,
          })),
          selectedLicenses: [],
          draggedItem: {},
        });
      })
      .catch((error) => {
        setProviderError("Unable to load license sets - try again later");
      });
  }, []);

  useEffect(() => {
    if (currentCustomer !== undefined && currentCustomer !== null) {
      setPageTitle(`Add License Pack - ${currentCustomer.name}`);
    } else {
      setPageTitle("Add License Pack");
    }
  }, [currentCustomer]);

  if (currentCustomer !== undefined && currentCustomer !== null) {
    setPageTitle(`Add License Pack - ${currentCustomer.name}`);
  } else {
    setPageTitle("Add License Pack");
  }

  const handleListBoxItemClick = (
    clickEvent: ListBoxItemClickEvent,
    clickSourceBox: string,
    otherBox: string
  ) => {
    //This method handles tracking of the selected or unselected state of an item in a ListBox
    setListBoxSelectionState({
      ...listBoxSelectionState,
      //find the item in ListBox which was clicked and inverse its click state
      //The code 'as keyof typeof' allows taking a string (clickSourceBox) and using it as a key
      //to access a prop in the state, and then update the prop's values
      [clickSourceBox]: listBoxSelectionState[
        clickSourceBox as keyof typeof listBoxSelectionState
      ].map((item: ListBoxItemProps) => {
        if (item.name === clickEvent.dataItem.name) {
          item[SELECTED_FIELD] = !item[SELECTED_FIELD];
        } else if (!clickEvent.nativeEvent.ctrlKey) {
          item[SELECTED_FIELD] = false;
        }
        return item;
      }),
      //for the other ListBox, set everything to unselected
      [otherBox]: listBoxSelectionState[
        otherBox as keyof typeof listBoxSelectionState
      ].map((item: ListBoxItemProps) => {
        item[SELECTED_FIELD] = false;
        return item;
      }),
    });
  };

  const handleListBoxToolBarClick = (
    e: ListBoxToolbarClickEvent,
    formRenderProps: FormRenderProps
  ) => {
    let toolName: string = e.toolName || "";
    let result: any = processListBoxData(
      listBoxSelectionState.availableLicenses,
      listBoxSelectionState.selectedLicenses,
      toolName,
      SELECTED_FIELD
    );
    setListBoxSelectionState({
      ...listBoxSelectionState,
      availableLicenses: result.listBoxOneData,
      selectedLicenses: result.listBoxTwoData,
    });

    //temporary type declaration in function to
    //ensure all additions to form have the same data structure
    type FormLicense = {
      dateAssigned: string;
      endDate: string;
      version: string;
      name: string;
      licenseSetId: string;
      items: IFSLicenseItem[];
    };

    if (toolName === "transferTo" || toolName === "transferAllTo") {
      //identify what is missing in the form that has been added to the 'selected' list of ListBox
      //and add this to the form via an onChange call
      const dataSetToMatch = result.listBoxTwoData as ListBoxItemProps[];
      const existingFormValues = formRenderProps.valueGetter(
        "licenses"
      ) as FormLicense[];
      var missingData = dataSetToMatch.filter(
        (dataItem) =>
          existingFormValues.find(
            (existingItem) => existingItem.name === dataItem.name
          ) == undefined
      );

      const defaultSDate =
        formRenderProps.valueGetter("creationDate") || defaultStartDate;
      const defaultEDate =
        formRenderProps.valueGetter("expireDate") || defaultEndDate;

      const addToForm: FormLicense[] = missingData.map((d) => {
        return {
          licenseSetId: d.id,
          dateAssigned: defaultSDate,
          endDate: defaultEDate,
          version: "0",
          name: d.name,
          items: d.items,
        } as unknown as FormLicense;
      });

      formRenderProps.onChange("licenses", {
        value: [...formRenderProps.valueGetter("licenses"), ...addToForm],
      });

      if (formError?.field === "licenceSet") {
        setFormError(undefined);
      }
    } else if (toolName === "transferFrom" || toolName === "transferAllFrom") {
      //identify what remains in the 'selected' list of ListBox
      //and set the form to only those values
      const dataSet = result.listBoxTwoData as ListBoxItemProps[];
      const existingValues = formRenderProps.valueGetter(
        "licenses"
      ) as FormLicense[];
      const remainingData = existingValues.filter(
        (e) => dataSet.find((d) => d.name === e.name) !== undefined
      );

      formRenderProps.onChange("licenses", { value: [...remainingData] });
    }
  };

  const handleFormSubmit = (e: any) => {
    if (currentCustomer == undefined) {
      setFormError({
        field: "customers",
        error: "Select a Customer to add a license",
      });
      return;
    }

    if (listBoxSelectionState.selectedLicenses.length == 0) {
      setFormError({
        field: "licenceSet",
        error: "Select a License Set to add a license",
      });
      return;
    }

    if (e.licenses) {
      e.licenses.forEach((license: any) => {
        license.version = "1";
        license.dateAssigned = e.creationDate;
        license.endDate = e.expireDate;
      });
    }

    setFormError(undefined);

    const newLicense: IFSLicenseRequest = {
      customerId: currentCustomer!.id!,
      description: e.description,
      creationDate: e.creationDate,
      expireDate: e.expireDate,
      name: e.name,
      licenseRequestData: e.licenses,
      siteReference: e.siteReference,
      salesAssetReference: e.salesassetreference,
      salesCustomerReference: e.salescustomerreference,
    };

    dataProvider
      ?.generateLicensePack(newLicense)
      .then((response) => {
        customerId == undefined
          ? navigate("/licenses")
          : navigate(`/licenses/${customerId}`);
      })
      .catch((error) => {
        setProviderError(
          "There was a problem adding this license, try again later"
        );
      });
  };

  const onCustomerSelectionChanged = (e: IFSCustomer) => {
    if (formError?.field == "customers") {
      setFormError(undefined);
    }

    setCurrentCustomer(e);
  };

  //custom handling for version field as this can escape Form validator. This avoid the following bug:
  //1) Fill in basic form information. Do not add any LicenseSets
  //2) Add a LicenseSet and click Submit
  //3) It allows submission of form
  const handleVersionFieldChange = (e: any) => {
    if (e.value.length > 0) {
      if (formError?.field == "licenseVersion") {
        setFormError(undefined);
      }
    }
  };

  return (
    <FSTFDefaultTemplate>
      <Card>
        <StackLayout className="layout-container k-m-4" orientation="vertical">
          <h2>New License</h2>
          {providerError && (
            <div className="error">
              <h3>{providerError}</h3>
            </div>
          )}

          {formError && (
            <div className="error">
              <h3>{formError.error}</h3>
            </div>
          )}

          <Form
            key={formKey}
            initialValues={{
              creationDate: defaultStartDate,
              expireDate: defaultEndDate,
              dateAssigned: defaultStartDate,
              endDate: defaultEndDate,
              licenses: [],
            }}
            onSubmit={handleFormSubmit}
            render={(formRenderProps: FormRenderProps) => (
              <FormElement>
                <fieldset className={"k-form-fieldset"}>
                  <div className="form-row">
                    <Field
                      name={"customer"}
                      label={"Customer"}
                      component={CustomerDropDown}
                      customers={customers}
                      onChange={onCustomerSelectionChanged}
                      customerId={customerId}
                    />
                    <Field
                      name={"name"}
                      label={"Name"}
                      component={LicenceTextInputField}
                      validator={formTextValidator}
                    />
                  </div>
                  <div className="form-row">
                    <Field
                      name={"creationDate"}
                      label={"Creation Date"}
                      component={LicenceDateInputField}
                    />
                    <Field
                      name={"expireDate"}
                      label={"Expire Date"}
                      component={LicenceDateInputField}
                    />
                    <Field
                      name={"siteReference"}
                      label={"Site reference"}
                      component={LicenceTextInputField}
                      validator={formTextValidator}
                    />
                  </div>
                  <Field
                    name={"description"}
                    label={"Description"}
                    component={LicenseTextAreaInputField}
                  />
                </fieldset>

                <div className="listbox-container">
                  <div className="row justify-content-center">
                    <div className="col k-pr-2">
                      <h3 className="k-m-4 ">Available License Sets</h3>
                      <ListBox
                        style={{ height: 250, width: "100%" }}
                        data={listBoxSelectionState.availableLicenses}
                        textField={LISTBOX_TEXT_FIELD}
                        selectedField={SELECTED_FIELD}
                        draggable={false}
                        onItemClick={(e: ListBoxItemClickEvent) =>
                          handleListBoxItemClick(
                            e,
                            AVAILABLE_LICENSES,
                            SELECTED_LICENSES
                          )
                        }
                        toolbar={() => {
                          return (
                            <ListBoxToolbar
                              tools={[
                                "transferTo",
                                "transferFrom",
                                "transferAllTo",
                                "transferAllFrom",
                              ]}
                              data={listBoxSelectionState.availableLicenses}
                              dataConnected={
                                listBoxSelectionState.selectedLicenses
                              }
                              onToolClick={(e) =>
                                handleListBoxToolBarClick(e, formRenderProps)
                              }
                            />
                          );
                        }}
                      />
                    </div>

                    <div className="col k-pl-0">
                      <h3 className="k-m-4 ">Selected License Sets</h3>
                      <ListBox
                        style={{ height: 250, width: "100%" }}
                        draggable={false}
                        data={listBoxSelectionState.selectedLicenses}
                        textField={LISTBOX_TEXT_FIELD}
                        selectedField={SELECTED_FIELD}
                        onItemClick={(e: ListBoxItemClickEvent) =>
                          handleListBoxItemClick(
                            e,
                            SELECTED_LICENSES,
                            AVAILABLE_LICENSES
                          )
                        }
                      />
                    </div>
                  </div>
                </div>

                <fieldset className={"k-form-fieldset"}>
                  <Field
                    name={"salesassetreference"}
                    label={"Sales Asset Reference"}
                    component={LicenceTextInputField}
                    validator={formTextValidator}
                  />
                  <Field
                    name={"salescustomerreference"}
                    label={"Opportunity ID"}
                    component={LicenceTextInputField}
                    validator={formTextValidator}
                  />
                </fieldset>

                <div className="k-form-buttons">
                  <Button
                    className="k-button"
                    type={"submit"}
                    disabled={!formRenderProps.allowSubmit}
                  >
                    Save
                  </Button>
                </div>
              </FormElement>
            )}
          />
        </StackLayout>
      </Card>
    </FSTFDefaultTemplate>
  );
};
