import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { Card, CardBody, CardHeader, StackLayout } from "@progress/kendo-react-layout";
import {  useContext, useEffect, useState } from "react";
import IFSUsers from "../../types/IFSUsers";
import { FSTFDataProvider } from "../../providers/FSTF.DataProvider";
import { IFSSiteRoles,IFSCustomerRoles, IFSRoles } from "../../types/IFSRoles";
import { FSAppContext } from "../../providers/FSTF.Context";
import { SvgIcon } from "@progress/kendo-react-common";
import { editToolsIcon, tellAFriendIcon,accessibilityIcon, trashIcon } from "@progress/kendo-svg-icons";
import { useNavigate } from "react-router-dom";
import { FSTFUserDetailForm } from "../molecules/FSTF.UserDetailForm";
import { FSTFCustomerUserRoleForm } from "../molecules/FSTF.CustomerUserRoleForm";
import IFSCustomer from "../../types/IFSCustomer";
import IFSCustomerUsers from "../../types/IFSCustomerUsers";
import { ConfirmDialog } from "../atoms/FSTF.ConfirmDialogs";



export const FSTFUsers = (props: any) => {
  const { isSiteAdmin,user,setUserValue,useFakeData,dataProvider, loggingService, setPageTitle} =useContext(FSAppContext);  
  const [userDetail, setUserDetail] = useState<IFSUsers | null>(null);
  const [allUsers, setAllUsers] = useState<IFSUsers[]>([]);  
  const [providerError, setProviderError] = useState<String>();
  const [type, setType] = useState("");
  
  const [showUserDetailForm, setShowUserDetailForm] = useState(false);
  const [siteRoles, setSiteRoles] = useState<IFSSiteRoles[]>([]);
  const [roles, setRoles] = useState<IFSRoles[]>([]);
  const [allActiveCustomers, setAllActiveCustomers] = useState<IFSCustomer[]>([]);
  const [assignCustomers, setAssignCustomers] = useState<IFSCustomerUsers[]>([]);
  const [showCustomerUserRoleForm, setShowCustomerUserRoleForm] = useState(false);

  const [showConfirmDialog, setShowConfirmDialog] = useState(false); 
  const [userToDelete, setUserToDelete] = useState<IFSUsers | null>(null); 

  const navigate = useNavigate();

  useEffect(() => {
    if (!isSiteAdmin) {
        navigate("/");
    }
  }, [ navigate]);

  const provider=dataProvider?dataProvider: new FSTFDataProvider(useFakeData);
  
  useEffect(() => { 

    setPageTitle("Users");

    provider?.getUsers().then((data) => {  
      const filtered=data.filter((user) =>user.active !==false );    
      setAllUsers(filtered);
      setProviderError(undefined);
    }).catch((error) => {
      setProviderError("Unable to retrieve all active user list"+error.message);
    }); 
    
    
    provider?.getSiteRoles().then((siteRoledata) => {
      setSiteRoles(siteRoledata);
      setProviderError(undefined);
    }).catch((error) => {
      setProviderError("Unable to retrieve siteRoles"+error.message);
    });   
    
    provider?.getCustomers().then((customersData) => {      
      const filtered=customersData.filter((customer) =>customer.active !==false );  
      setAllActiveCustomers(filtered);
      setProviderError(undefined);
    }).catch((error) => {
      setProviderError("Unable to retrieve siteRoles"+error.message);
    });   
    
    provider?.getCustomerRoles().then((rolesData) => {
      setRoles(rolesData);
      setProviderError(undefined);
    }).catch((error) => {
      setProviderError("Unable to retrieve user roles "+error.message);
    }); 
  },[]);  

  const EditCell = (props:any) => (      
    <td>   
    <div title="Edit">
        <SvgIcon  icon={editToolsIcon} onClick={() => handleEditClick(props.dataItem)} />
    </div> 
  </td> 
  );
  
  const handleEditClick = (item: IFSUsers) => {
    setProviderError(undefined);
    setUserDetail(item);
    setType("Update");
    setShowUserDetailForm(true);
    setShowCustomerUserRoleForm(false);
  }
  const handleCancel = () => {    
    setProviderError(undefined);
    setUserDetail(null);
    setAssignCustomers([])
    setShowUserDetailForm(false);  
    setShowCustomerUserRoleForm(false);  
  };

  const handleUserDetailFormSubmit = (finalUserDetail: IFSUsers,customerUserRoles:IFSCustomerRoles[]|undefined,siteRoles:IFSSiteRoles[]|undefined) => {
    if(finalUserDetail){ 
      finalUserDetail.modifiedByUserName=user.userName;       
      finalUserDetail.password="Password@123" // to make dto valid    
      if(siteRoles){
        finalUserDetail.siteRoles=siteRoles.map(s=>s.roleName);
        finalUserDetail.fullSiteRoles=undefined;//siteRoles;
      }
      if(customerUserRoles){
        finalUserDetail.companyRoles=customerUserRoles;
      }
      
      provider?.putCustomerUser(finalUserDetail)
          .then((data) => {  
            setAllUsers(currentUsers => 
              currentUsers.map(user => 
                user.id === data.id ? data : user
              )
            );
            setProviderError(undefined);
            if(finalUserDetail.id===user.id && setUserValue){
              setUserValue(finalUserDetail);
            }
          }).catch((error) => {
            setProviderError("Unable to update user");
          });
      
    } 
    setUserDetail(null);
    setShowUserDetailForm(false);
    setShowCustomerUserRoleForm(false);
  };

  const DeleteCell = (props:any) => (  
    <td>   
      <div title="Delete">
          <SvgIcon  icon={trashIcon}  onClick={() => handleDeleteClick(props.dataItem)}/>
      </div> 
    </td> 
  ); 

  const handleDeleteClick = (item: IFSUsers) => {    
    setProviderError(undefined); 
    setUserToDelete(item); 
    setShowConfirmDialog(true); 
    
    setShowUserDetailForm(false);
    setShowCustomerUserRoleForm(false);
  };

  const handleConfirmDelete = () => {
    if (userToDelete) {
      const item = { ...userToDelete, active: false, modifiedByUserName: user.userName };

      provider
        ?.deleteUser(item)
        .then(() => {
          setAllUsers((allUsers) =>
            allUsers.filter((alluser) => alluser.id !== item.id)
          );
          if (item.id === user.id && setUserValue) setUserValue(item);
        })
        .catch((error) => {
          setProviderError("Unable to delete user " + error.message);
        })
        .finally(() => {
          setUserToDelete(null); 
          setShowConfirmDialog(false);
        });
    }
  };

  const handleCancelDelete = () => {
    setUserToDelete(null); 
    setShowConfirmDialog(false);
  };


  const RoleCell = (props:any) => (      
    <td>   
    <div title="Assign Roles">
      <SvgIcon icon={accessibilityIcon} onClick={() => handleRoleClick(props.dataItem)}>Role
      </SvgIcon>
    </div> 
  </td> 
  );

  const handleRoleClick = (item: IFSUsers) => {
    setProviderError(undefined);
    if(item.id) {    
      setUserDetail(item);
      provider?.getUserCustomers(item.id).then((data) => {    
        const filtered=data.filter((customer) =>customer.active !==false ); 
        setAssignCustomers(filtered);
        setProviderError(undefined);
        setShowCustomerUserRoleForm(true);

      }).catch((error) => { 
        setProviderError("Unable to retrieve customers for selected users "+error.message);
      });    
      
    }
    setType("Assign Roles");
    setShowUserDetailForm(false); 
    setShowCustomerUserRoleForm(true);
  }
  
  const handleCustomerUserRoleFormSubmit = (finalUserDetail: IFSCustomerUsers,rolesData:string[]) => {    
    if(finalUserDetail){          
      const matchingRecord = assignCustomers.find(u => 
        u.customerId === finalUserDetail.customerId && u.userId === finalUserDetail.userId);
      const existingId= matchingRecord? matchingRecord.id : null 
      const newId= existingId? existingId : "";
      finalUserDetail.id=newId;      
      finalUserDetail.roles=rolesData;
      finalUserDetail.active=true;
      finalUserDetail.modifiedBy=user.userName; 
      if(existingId !==null) 
      {
        provider?.putCustomerUserRoles(finalUserDetail)
          .then(() =>  {
            setProviderError(undefined);
          }).catch((error) => {
            setProviderError("Unable to update customer and Role to user "+error.message);
        });      
      }
      else
      {        
        provider?.postCustomerUserRoles(finalUserDetail)
          .then(() =>  {
            setProviderError(undefined);
          }).catch((error) => {
            setProviderError("Unable to assign customer and Role to user "+error.message);
        }); 
      }     
    } 
    provider?.getUsers().then((data) => {  
      const filtered=data.filter((user) =>user.active !==false );    
      setAllUsers(filtered);
      setProviderError(undefined);
    }).catch((error) => {
      setProviderError("Unable to retrieve all active user list"+error.message);
    }); 
    setAssignCustomers([]);
    setUserDetail(null);
    setShowUserDetailForm(false);    
    setShowCustomerUserRoleForm(false);
  };
  
  return (
    <div className="k-m-5">
      <StackLayout orientation="vertical" >
        <Card className="fs-users">       
        {providerError && (
        <CardHeader>
            <div className="error">
              <h3>{providerError}</h3>
            </div>   
            </CardHeader>         
          )}
         <CardBody className="fs-users-body">
         <h2>Pane login account list</h2>

          <Grid data={allUsers} 
           resizable={true}
           scrollable={"scrollable"}
           sortable={true}
           size={"small"}
           className="fs-users-grid"> 
            <Column field="firstName" title="FirstName"/>
            <Column field="lastName" title="LastName"  />
            <Column field="userName" title="UserName"/>
            <Column field="email" title="Email" /> 
            <Column field="mobile" title="Mobile" /> 
            <Column field="siteRoles" title="SiteRole"/> 
            <Column cell={EditCell} className="fs-icon-button" width={"50px"}/>       
            <Column cell={RoleCell} className="fs-icon-button" width={"50px"} />     
            <Column cell={DeleteCell} className="fs-icon-button" width={"50px"} />      
          </Grid>
          </CardBody>
          </Card>
         
      {showUserDetailForm && !showCustomerUserRoleForm && (
        <FSTFUserDetailForm onSubmit={handleUserDetailFormSubmit} onCancel={handleCancel} selectedUser={userDetail?userDetail:{}} roles={roles} siteRoles={siteRoles} customerId={""} type={type}/>
      )}
      
      {showCustomerUserRoleForm&& !showUserDetailForm && (
        <FSTFCustomerUserRoleForm 

          onSubmit={handleCustomerUserRoleFormSubmit} 
          onCancel={handleCancel} 
          allActiveCustomers={allActiveCustomers} 
          customerRolesAvailable={roles} 
          selectedUserId={userDetail?.id?userDetail.id:""} 
          selectedUser={userDetail} 
          assignedCustomerRoles={assignCustomers} 
          type={type}/>
      )}

        {showConfirmDialog && userToDelete && (
          <ConfirmDialog
            title="Delete User"
            message={`Are you sure you want to delete user: ${userToDelete.firstName} ${
              userToDelete.lastName || ""
            }?`}
            onConfirm={handleConfirmDelete}
            onCancel={handleCancelDelete}
          />
        )}
      </StackLayout>
    </div>  
  );
};


