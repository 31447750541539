import React from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Button } from '@progress/kendo-react-buttons';

export interface ConfirmDialogProps {
  title: string;
  message: string;
  onConfirm: () => void;
  onCancel: () => void;
}

export const ConfirmDialog: React.FC<ConfirmDialogProps> = ({ title, message, onConfirm, onCancel }) => {
  return (
    <Dialog className="fs-dialogbox" title={title} onClose={onCancel}>
      <p>{message}</p>
      <DialogActionsBar>
        <Button themeColor={"light"} onClick={onCancel}>
          No
        </Button>
        <Button themeColor={"primary"} className="k-button k-primary-solid-primary" onClick={onConfirm}>
          Yes
        </Button>
      </DialogActionsBar>
    </Dialog>
  );
};

